import { PAYMENT_METHOD } from '@oneecosystem/dealshaker-core';
import { paymentStatus, deliveryStatus } from 'enums';

export const orderDeliveryDetails = delivery => ({
  isDeliveryPacked: delivery?.statusId === deliveryStatus.packed,
  isDeliveryOnGoing: delivery?.statusId === deliveryStatus.ongoing,
  isDeliveryCompleted: delivery?.statusId === deliveryStatus.delivered,
});

export const orderPaymentDetails = payment => {
  const isFiatManualPayment =
    payment?.cashPaymentMethodId === PAYMENT_METHOD.CASH_ON_DELIVERY ||
    payment?.cashPaymentMethodId === PAYMENT_METHOD.BANK_TRANSFER;

  const isFiatPaymentCompleted = payment?.cashPaymentStatus === paymentStatus.completed;
  const isCryptoPaymentCompleted = payment?.cryptoPaymentStatus === paymentStatus.completed;

  return {
    isFiatManualPayment,
    isFiatPaymentCompleted,
    isCryptoPaymentCompleted,
    isFiatPaymentPending: payment?.cashPaymentStatus === paymentStatus.pending,
    isCryptoPaymentPending: payment?.cryptoPaymentStatus === paymentStatus.pending,
    isPaymentCompleted: isFiatPaymentCompleted && isCryptoPaymentCompleted,
    isFiatPaymentBank: payment?.cashPaymentMethodId === PAYMENT_METHOD.BANK_TRANSFER,
    isFiatPaymentWithCoinPayments: payment?.cashPaymentMethodId === PAYMENT_METHOD.COIN_PAYMENTS,
    isFiatPaymentWithStripe: payment?.cashPaymentMethodId === PAYMENT_METHOD.STRIPE,
    isAutomaticPaymentsCompleted: (isFiatManualPayment || isFiatPaymentCompleted) && isCryptoPaymentCompleted,
  };
};

export const editOrderCashPaymentStatus = (order, cashPaymentStatus) => ({
  ...order,
  payment: { ...order.payment, cashPaymentStatus },
});

export const editOrderDeliveryStatus = (order, statusId) => ({
  ...order,
  delivery: { ...order.delivery, statusId },
});

export const editOrderStatus = (order, statusId) => ({
  ...order,
  statusId,
  delivery: { ...order.delivery, statusId: deliveryStatus.delivered },
  payment: { ...order.payment, cashPaymentStatus: paymentStatus.completed },
});

export const orderDeliveryAddress = delivery => {
  const country = delivery?.buyerDeliveryAddress?.country ?? delivery?.businessPickupAddress?.country;
  const city = delivery?.buyerDeliveryAddress?.city ?? delivery?.businessPickupAddress?.city;
  const street = delivery?.buyerDeliveryAddress?.street ?? delivery?.businessPickupAddress?.street;
  const postcode = delivery?.buyerDeliveryAddress?.postcode ?? delivery?.businessPickupAddress?.postcode;

  return {
    country,
    city,
    postcode,
    street,
    address:
      [street?.trim(), postcode ? `${city?.trim()} ${postcode.trim()}` : city?.trim(), country?.trim()]
        .filter(Boolean)
        .join(', ') || 'N/A',
  };
};
