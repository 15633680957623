import { validatePaymentIntentStatus, setError } from 'services';

export const validatePaymentStatus = (orderId, successCallback) => {
  const validateStatus = async () => {
    const [res, err] = await validatePaymentIntentStatus(orderId);
    if (err) return setError(err);

    if (res.status === 'succeeded') successCallback();
    else setTimeout(validateStatus, 5000);
  };

  validateStatus();
};