import React from 'react';
import { pick, concatStrings } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import {
  DEFAULT_FIAT_CURRENCY,
  PAYMENT_METHODS_CURRENCIES,
  EXTERNAL_PAYMENT_PROVIDERS,
} from '@oneecosystem/dealshaker-core';
import { changeOrderCashPaymentStatus, useOrders, selectedOrderPaymentDetails } from 'stores';
import { orderStatus } from 'enums';
import { getPrecisionByExternalPaymentMethod } from 'utils';
import { PriceLabel } from 'components/shared';
import {
  OrderDetailsPaymentWallet,
  OrderDetailsBankPaymentDetails,
  OrderDetailsCryptoPaymentDetails,
} from './PaymentDetails';
import { paymentTypeBoxesConfig } from './config';
import { orderDetailsPaymentContainer } from './styles';

export const OrderDetailsPayment = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const {
    id: orderId,
    payment,
    totalPriceCrypto,
    totalPriceFiat,
    editable,
    bankDetails,
    statusId: statusOrder,
    currencyCode,
  } = useOrders(ordersState => ({
    bankDetails: ordersState?.selectedOrder?.business?.bankDetails,
    ...pick(ordersState?.selectedOrder, [
      'totalPriceCrypto',
      'totalPriceFiat',
      'id',
      'statusId',
      'payment',
      'currencyCode',
    ]),
    editable: ordersState?.editable,
  }));
  const {
    isFiatManualPayment,
    isFiatPaymentBank,
    isAutomaticPaymentsCompleted,
    isFiatPaymentPending,
    isFiatPaymentCompleted,
    isFiatPaymentWithCoinPayments,
  } = selectedOrderPaymentDetails();
  const cashConfig = paymentTypeBoxesConfig[payment?.cashPaymentMethodId];
  const cryptoConfig = paymentTypeBoxesConfig[payment?.cryptoPaymentMethodId];
  const isEditable = editable && statusOrder === orderStatus.new;
  const cashPaymentCurrency = PAYMENT_METHODS_CURRENCIES[payment?.cashPaymentMethodId];
  const printPriceProps = {
    fiat: totalPriceFiat,
    fiatOriginCurrency: currencyCode,
    crypto: totalPriceCrypto,
    cryptoDesiredCurrency: PAYMENT_METHODS_CURRENCIES[payment?.cryptoPaymentMethodId],
    fiatPrecision: getPrecisionByExternalPaymentMethod(PAYMENT_METHODS_CURRENCIES[payment?.cashPaymentMethodId]),
    fiatOnly: false,
  };

  const fiatPrintPriceProps = {
    ...printPriceProps,
    crypto: null,
    fiatDesiredCurrency:
      (cashPaymentCurrency || '').toLowerCase() === EXTERNAL_PAYMENT_PROVIDERS.COIN_PAYMENTS.CODE.toLowerCase()
        ? cashPaymentCurrency
        : currencyCode,
  };

  return (
    <>
      <h3 className="order-details-section-title">{getText('payment')}</h3>
      <section
        className={concatStrings('order-details-section', orderDetailsPaymentContainer(theme))}
        id="order-details-payment-section"
      >
        <div className="order-details-payment-info-row">
          <h5 className="order-details-payment-info-label">{getText('type')}</h5>
          <span>
            {totalPriceFiat
              ? `${getText(cashConfig?.title)} + ${getText(cryptoConfig?.title)}`
              : getText(cryptoConfig?.title)}
          </span>
        </div>

        <h5 className="order-details-payment-info-label">{getText('totalAmount')}</h5>
        <div className="order-details-payment-info-row" data-id="order-details-payment-deal-price">
          <h6 className="order-details-payment-info-secondary-label">{getText('dealCurrency')}</h6>
          <p>
            <PriceLabel {...printPriceProps} fiatDesiredCurrency={currencyCode} fiatPrecision={null} />
          </p>
        </div>

        {(currencyCode !== DEFAULT_FIAT_CURRENCY.code || isFiatPaymentWithCoinPayments) && !isFiatManualPayment && (
          <div className="order-details-payment-info-row" data-id="order-details-payment-payment-price">
            <h6 className="order-details-payment-info-secondary-label">{getText('yourPaymentCurrency')}</h6>
            <p>
              <PriceLabel
                {...printPriceProps}
                fiatDesiredCurrency={
                  isFiatPaymentWithCoinPayments
                    ? EXTERNAL_PAYMENT_PROVIDERS.COIN_PAYMENTS.CODE
                    : DEFAULT_FIAT_CURRENCY.code
                }
              />
            </p>
          </div>
        )}

        <h4 className="order-details-payment-headline">{getText('paymentStatus')}</h4>
        <div data-id="order-details-crypto-payment-status">
          <OrderDetailsPaymentWallet
            iconName={cryptoConfig?.icon}
            title={getText(cryptoConfig?.title)}
            subTitle={getText(cryptoConfig?.subTitle)}
            printPriceProps={{ ...printPriceProps, fiat: null }}
            orderPaymentStatus={payment?.cryptoPaymentStatus}
          />
        </div>

        {!!totalPriceFiat && (
          <div data-id="order-details-fiat-payment-status">
            <OrderDetailsPaymentWallet
              iconName={cashConfig?.icon}
              title={getText(cashConfig?.title)}
              subTitle={getText(cashConfig?.subTitle)}
              printPriceProps={fiatPrintPriceProps}
              orderPaymentStatus={payment?.cashPaymentStatus}
              editable={editable}
              isPaymentEditable={
                isEditable && isFiatManualPayment && isAutomaticPaymentsCompleted && !isFiatPaymentCompleted
              }
              onChange={statusId => changeOrderCashPaymentStatus(orderId, statusId)}
              disabled={!isAutomaticPaymentsCompleted}
              paymentMethod={payment?.cashPaymentMethodId}
              orderId={orderId}
            />
          </div>
        )}
        {!editable && isFiatPaymentBank && !!totalPriceFiat && isFiatPaymentPending && (
          <OrderDetailsBankPaymentDetails bankDetails={bankDetails} />
        )}
        {!editable && isFiatPaymentWithCoinPayments && !!totalPriceFiat && isFiatPaymentPending && (
          <OrderDetailsCryptoPaymentDetails orderId={orderId} fiatPrintPriceProps={fiatPrintPriceProps} />
        )}
      </section>
    </>
  );
};
