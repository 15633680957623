import dotenv from 'dotenv';

dotenv.config();

export default {
  apiAddress: process.env.REACT_APP_API,
  messagingCenterApi: process.env.REACT_APP_MESSAGING_CENTER_API,
  keycloakUrl: process.env.REACT_APP_KEYCLOAK_URL,
  oneLifeApiUrl: process.env.REACT_APP_ONELIFE_API,
  strankEnvironment: process.env.REACT_APP_STRANK_ENVIRONMENT,
  oneLifePortalUrl: process.env.REACT_APP_ONELIFE_PORTAL,
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
};
