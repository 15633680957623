import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isString, isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { cryptoSign, useCurrencies, findFiatCurrency, convertCurrencies } from '@oneecosystem/dealshaker-core';
import { mul, div, sub } from 'exact-math';
import FeatherIcon from 'feather-icons-react';
import { Form, Row, Input, Slider, InputNumber, Popover } from 'antd';
import { DatePicker, NestedCol } from 'components/ui';
import { Dropdown } from 'components/UIExternal';
// TODO: REMOVE DISCOUNT FIELD
// import { BorderedShadowlessGrayBox} from 'components/ui';
import { dealWeightTypeSelect } from 'enums';
import useError from 'services/errorHandling/useError';
import { isValidDaysPeriod, isFutureOrSameDate } from 'utils/valueFormatter';
import { updateDealStepData } from 'services/api/dealsService';
import { PriceLabel } from 'components/shared';
import FormActions from '../FormActions/FormActions';
import {
  couponCountInput,
  // TODO: REMOVE DISCOUNT FIELD
  // discountBox,
  formStyle,
  pricePicker,
  packageStyles,
  label,
  datePickerStyles,
  popoverContainer,
  bvPointsInfoPopupText,
} from './styles';

const StepTwo = ({ stepTwoData, currentStep, dealId, setManualStep, minOneCoinPercent }) => {
  const theme = useTheme();
  useCurrencies(currenciesState => currenciesState?.fiatCurrencies);
  const [form] = Form.useForm();
  const totalPrice = Number(Form.useWatch('totalPrice', form)) || 0;
  const percentRatio = Form.useWatch('percentRatio', form) ?? minOneCoinPercent;
  // const discount = Form.useWatch('discount', form) ?? 0;
  const { setError } = useError();
  const { getText } = useTranslations();
  const [showFiltersError, setShowFiltersError] = useState({ startDate: false, endDate: false });
  const businessCurrency = findFiatCurrency(stepTwoData?.currencyCode);

  // TODO: REMOVE DISCOUNT FIELD
  // const calculateDiscountedPrice = (price, discountPercent) => (price * (1 - discountPercent / 100))?.toFixed(2);

  const validateBvPoints = () => ({
    validator: (_, value) => {
      const maxBv = convertCurrencies({ fiat: totalPrice, fiatOriginCurrency: businessCurrency?.code })?.fiatBase;
      const regexStr = /^\d+$/;
      let resultMessage = null;
      if (!regexStr.test(value) && value > 0) resultMessage = getText('aWholeNumber');
      if (Math.floor(maxBv) < value) resultMessage = getText('bvPointsLowerThan', { totalPrice: Math.floor(maxBv) });
      return maxBv < value || (!regexStr.test(value) && value > 0) ? Promise.reject(resultMessage) : Promise.resolve();
    },
  });

  const validateTotalPrice = () => ({
    validator: () => {
      const regexStr = /^\d+([.]\d{1,2})?$/;
      let resultMessage = null;
      if (Number(totalPrice) <= 0) resultMessage = getText('priceBiggerThanZero');
      if (!regexStr.test(totalPrice)) resultMessage = getText('pleaseEnterValidNumber');

      return resultMessage ? Promise.reject(resultMessage) : Promise.resolve();
    },
  });

  const onFormFinishHandler = data => {
    const formattedData = {
      ...data,
      bvPoints: !isNil(data.bvPoints) ? parseInt(data.bvPoints, 10) : 0,
      // TODO: REMOVE DISCOUNT FIELD
      // discount: parseInt(data.discount, 10) || 0,
      percentRatio: data?.percentRatio ?? minOneCoinPercent,
      discount: 0,
      totalPrice: parseFloat(data.totalPrice),
      startDate: isString(data.startDate) ? data.startDate : data.startDate.format('YYYY-MM-DD'),
      endDate: isString(data.endDate) ? data.endDate : data.endDate.format('YYYY-MM-DD'),
      height: parseFloat(Number(data.height).toFixed(4)) ?? 0,
      width: parseFloat(Number(data.width).toFixed(4)) ?? 0,
      length: parseFloat(Number(data.length).toFixed(4)) ?? 0,
      weight: parseFloat(Number(data.weight).toFixed(4)) ?? 0,
      weightTypeId: Number(data.weightTypeId),
    };

    if (dealId) handleUpdateStepData(formattedData);
  };

  const handleUpdateStepData = async data => {
    const [, err] = await updateDealStepData(data, 2, dealId);
    err ? setError(err) : setManualStep(3);
  };

  const onFinishFailed = errorInfo => {
    if (errorInfo && isNil(errorInfo?.values.startDate)) setShowFiltersError(prev => ({ ...prev, startDate: true }));
    if (errorInfo && isNil(errorInfo?.values.endDate)) setShowFiltersError(prev => ({ ...prev, endDate: true }));
    if (errorInfo && !isNil(errorInfo?.values.endDate) && !isNil(errorInfo?.values.startDate))
      setShowFiltersError({ startDate: false, endDate: false });
  };

  return (
    <Form
      className={`${formStyle} block-form-item`}
      onFieldsChange={changedValue => {
        if (changedValue.find(el => el.name.includes('startDate')))
          setShowFiltersError(prev => ({ ...prev, startDate: false }));
        if (changedValue.find(el => el.name.includes('endDate')))
          setShowFiltersError(prev => ({ ...prev, endDate: false }));
      }}
      form={form}
      scrollToFirstError
      initialValues={{
        ...stepTwoData,
        percentRatio: stepTwoData?.percentRatio || minOneCoinPercent,
        totalPrice: stepTwoData?.totalPrice.toFixed(2),
      }}
      onFinish={onFormFinishHandler}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={[24, 0]}>
        <NestedCol lg={4} span={24} totalWidth={14}>
          <Form.Item
            labelCol={{ lg: 24 }}
            name="startDate"
            valuePropName="defaultValue"
            labelAlign="left"
            label={getText('dealLaunchDate')}
            shouldUpdate
            rules={[
              {
                required: true,
                message: getText('pleaseSpecifyStartDate'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || isFutureOrSameDate(getFieldValue('startDate'))) return Promise.resolve();

                  return Promise.reject(getText('startDateInPast'));
                },
              }),
            ]}
            style={{ marginBottom: 10 }}
          >
            <DatePicker className={datePickerStyles(showFiltersError.startDate, theme)} />
          </Form.Item>
        </NestedCol>
        <NestedCol lg={4} span={24} totalWidth={14}>
          <Form.Item
            labelCol={{ lg: 24 }}
            name="endDate"
            valuePropName="defaultValue"
            labelAlign="left"
            label={getText('dealEndDate')}
            shouldUpdate
            rules={[
              {
                required: true,
                message: getText('pleaseSpecifyEndDate'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || isValidDaysPeriod(getFieldValue('startDate'), getFieldValue('endDate')))
                    return Promise.resolve();

                  return Promise.reject(getText('endDateAfterStartDate'));
                },
              }),
            ]}
            style={{ marginBottom: 10 }}
          >
            <DatePicker
              className={datePickerStyles(showFiltersError.endDate, theme)}
              disabledDate={date => date && stepTwoData.isLockTimeRange}
            />
          </Form.Item>
        </NestedCol>
        {stepTwoData.isLockTimeRange && (
          <NestedCol lg={14} span={24} totalWidth={14}>
            <b>{getText('isLockedTimeRangePortal')}</b>
          </NestedCol>
        )}
        <NestedCol lg={9} span={24} totalWidth={14}>
          <Form.Item
            labelCol={{ lg: 24 }}
            name="totalPrice"
            labelAlign="left"
            label={getText('fullPriceOfDeal')}
            rules={[{ required: true }, validateTotalPrice]}
          >
            <Input type="number" prefix={<span>{businessCurrency?.symbol}</span>} />
          </Form.Item>
          {/* TODO: REMOVE DISCOUNT FIELD  */}
          {/* <Form.Item
            labelCol={{ lg: 24 }}
            name="discount"
            labelAlign="left"
            label={getText('discount')}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || (getFieldValue('discount') > 0 && getFieldValue('discount') < 100))
                    return Promise.resolve();

                  return Promise.reject(getText('discountShouldBeBetweenStartEnd', { start: 0, end: 100 }));
                },
              }),
            ]}
          >
            <Input type="number" prefix={<span className="icon-Percent" />} />
          </Form.Item> */}
        </NestedCol>
        {/* TODO: REMOVE DISCOUNT FIELD */}
        {/* <NestedCol lg={5} span={24} totalWidth={14}>
          {discountedPrice && discountedPrice > 0 && (
            <BorderedShadowlessGrayBox className={discountBox(theme)} theme={theme}>
              <label>{getText('discountPriceOfDeal')}</label>
              <p>{discountedPrice}</p>
            </BorderedShadowlessGrayBox>
          )}
        </NestedCol> */}
        <NestedCol lg={9} span={24} totalWidth={14}>
          <div className={pricePicker(theme)}>
            <label>{getText('percentPaidWithPrice', { price: cryptoSign() })}</label>
            <h5>
              <PriceLabel
                fiat={mul(totalPrice, div(sub(100, percentRatio ?? minOneCoinPercent), 100))}
                fiatOriginCurrency={businessCurrency?.code}
                fiatDesiredCurrency={businessCurrency?.code}
                fiatCrypto={mul(totalPrice, div(percentRatio ?? minOneCoinPercent, 100))}
                fiatOnly={false}
              />
            </h5>
            <Form.Item name="percentRatio">
              <Slider
                tooltipVisible
                tooltipPlacement="bottom"
                min={minOneCoinPercent}
                marks={{ [minOneCoinPercent]: `${minOneCoinPercent}%`, 100: '100%' }}
              />
            </Form.Item>
            <p>
              {getText('adjustYourCouponPriceRatio', {
                start: cryptoSign(),
                end: cryptoSign(),
              })}
            </p>
          </div>
        </NestedCol>
        {stepTwoData?.allowsBVPoints && (
          <NestedCol lg={9} span={24} totalWidth={14}>
            <Form.Item
              labelCol={{ lg: 24 }}
              labelAlign="left"
              name="bvPoints"
              dependencies={['totalPrice']}
              label={
                <Popover
                  content={getText('bvPointsHelp')}
                  overlayClassName={popoverContainer(theme)}
                  zIndex={900}
                  placement="bottomRight"
                  trigger="click"
                >
                  <div className={bvPointsInfoPopupText}>
                    <p>{getText('bvPoints')}</p>
                    <FeatherIcon icon="info" size={12} />
                  </div>
                </Popover>
              }
              rules={[validateBvPoints]}
            >
              <InputNumber
                className={couponCountInput}
                placeholder={getText('typeBvPoints')}
                min={0}
                type="number"
                defaultValue={0}
              />
            </Form.Item>
          </NestedCol>
        )}
        <NestedCol lg={9} span={24} totalWidth={14}>
          <Form.Item
            labelCol={{ lg: 24 }}
            labelAlign="left"
            label={getText('quantityOfCoupons')}
            name="couponsQuantity"
            rules={[
              {
                required: true,
                message: getText('pleaseSpecifyCouponQuantity'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('couponsQuantity') >= 1) return Promise.resolve();

                  return Promise.reject(getText('couponShouldBeMoreZero'));
                },
              }),
            ]}
            style={{ marginBottom: 10 }}
          >
            <InputNumber
              disabled={stepTwoData.isLockQuantity}
              className={couponCountInput}
              placeholder={getText('select')}
              min={1}
            />
          </Form.Item>
          {stepTwoData.isLockQuantity && (
            <Form.Item style={{ marginBottom: 5 }}>
              <NestedCol lg={14} span={24} totalWidth={14}>
                <b>{getText('isLockedQuantityPortal')}</b>
              </NestedCol>
            </Form.Item>
          )}
        </NestedCol>
        <NestedCol lg={5} span={24} totalWidth={14} />
        <NestedCol lg={14} span={24} totalWidth={14}>
          <b>{getText('oneMonthExpirationDate')}</b>
        </NestedCol>
        <NestedCol lg={14} span={24} totalWidth={14}>
          <p className={packageStyles}>{getText('packageSize')}</p>
        </NestedCol>
        <NestedCol lg={3} span={24} totalWidth={14}>
          <div className={label(theme)}>
            <Form.Item labelCol={{ lg: 24 }} labelAlign="left" label={getText('heightSize')} name="height">
              <Input type="number" suffix={<span>cm</span>} step="0.0001" min={0} />
            </Form.Item>
          </div>
        </NestedCol>
        <NestedCol lg={3} span={24} totalWidth={14}>
          <div className={label(theme)}>
            <Form.Item labelCol={{ lg: 24 }} labelAlign="left" label={getText('widthSize')} name="width">
              <Input type="number" suffix={<span>cm</span>} step="0.0001" min={0} />
            </Form.Item>
          </div>
        </NestedCol>
        <NestedCol lg={3} span={24} totalWidth={14}>
          <div className={label(theme)}>
            <Form.Item labelCol={{ lg: 24 }} labelAlign="left" label={getText('depthSize')} name="length">
              <Input type="number" suffix={<span>cm</span>} step="0.0001" min={0} />
            </Form.Item>
          </div>
        </NestedCol>
        <NestedCol lg={14} span={24} totalWidth={14}>
          <p className={packageStyles}>{getText('packageWeight')}</p>
        </NestedCol>
        <NestedCol lg={6} span={24} totalWidth={14}>
          <Form.Item labelCol={{ lg: 24 }} labelAlign="left" name="weight">
            <Input type="number" placeholder="00.00" step="0.0001" min={0} />
          </Form.Item>
        </NestedCol>
        <NestedCol lg={3} span={24} totalWidth={14}>
          <Form.Item labelCol={{ lg: 24 }} labelAlign="left" name="weightTypeId">
            <Dropdown
              noClear
              options={dealWeightTypeSelect}
              placeholder={getText('weightType')}
              mapValue={el => el?.value}
            />
          </Form.Item>
        </NestedCol>
        <NestedCol lg={14} span={24} totalWidth={14}>
          <Form.Item valuePropName="defaultValue">
            <FormActions
              setManualStep={setManualStep}
              currentStep={currentStep}
              onNextStepHandler={() => form.submit()}
            />
          </Form.Item>
        </NestedCol>
      </Row>
    </Form>
  );
};

StepTwo.propTypes = {
  stepTwoData: PropTypes.object,
  currentStep: PropTypes.number,
  setManualStep: PropTypes.func,
  dealId: PropTypes.number,
  minOneCoinPercent: PropTypes.number,
};
export default StepTwo;
