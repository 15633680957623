import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const youTubePlayIcon = theme =>
  css({
    position: 'relative',
    '& .icon-Play': {
      position: 'absolute',
      background: theme.gray_rgba,
      color: 'white',
      left: '1.875rem',
      top: '1.25rem',
      borderRadius: '50%',
      height: '1.25rem',
      width: '1.25rem',
    },
  });

export const video = css({
  height: '100% !important',
  top: 0,
  '& iframe': {
    height: '100%',
    width: '100%',
  },
});

export const galleryModal = theme =>
  css({
    '& .ant-modal': {
      maxWidth: '50rem',
      [Mobile]: {
        width: '100% !important',
      },
    },

    '& .ant-modal-content': {
      maxWidth: '50rem',
      padding: '0 2em',

      [Mobile]: {
        width: '100%',
      },
    },

    '& .ant-modal-body': {
      padding: '0.625em 0',

      '& h1': {
        margin: 0,
        marginTop: '1em',
      },
    },

    '& .image-gallery-slide': {
      width: '100%',
      borderRadius: 8,
    },

    '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
      maxWidth: '50rem',
      maxHeight: 'calc(100vh - 400px)',
    },

    '& .image-gallery-right-nav': {
      padding: 0,
      filter: 'none',
      right: '-3.4375em',
      position: 'absolute',
      [Mobile]: { right: 0 },
      '& svg': {
        color: theme.gray200,
        height: '3.125rem',
        width: '1.875rem',
      },
    },
    '& .image-gallery-left-nav': {
      filter: 'none',
      padding: 0,
      position: 'absolute',
      left: '-3.4375em',
      [Mobile]: { left: 0 },
      '& svg': {
        color: theme.gray200,
        height: '3.125rem',
        width: '1.875rem',
      },
    },
  });

export const dealDetailsContainer = css({
  paddingBottom: '6.25em',
  [Mobile]: {
    '& .section-title': {
      fontSize: '1.375rem',
      wordWrap: 'break-word',
    },
  },
});

export const previewOptions = theme =>
  css({
    backgroundColor: theme.skyblue,
    borderRadius: 4,
    padding: '0.625em 0',
    alignItems: 'center',
    marginBottom: '2.375em',
    '& label': { marginLeft: '1.25em' },
  });

export const textUnderline = css({
  textDecoration: 'underline',
  padding: 0,
});

export const thumbnailTag = css({
  display: 'inline-block',
});

export const messagesLink = theme =>
  css({
    color: `${theme.gray200} !important`,
    fontSize: '0.75rem',
    [Mobile]: {
      paddingLeft: '0.3125em',
    },
  });

export const socialContainer = css({
  position: 'absolute',
  right: 0,
  bottom: '1.125em',
  [Mobile]: {
    position: 'relative',
    bottom: 0,
    justifyContent: 'flex-start',
    marginTop: '1.5625em',
    marginBottom: '1.5625em',
  },
});

export const contentRow = css({
  marginTop: '1.875em',
  [Mobile]: {
    '& .ant-row': {
      flexFlow: 'row nowrap',
      width: '100%',
      overflowX: 'auto',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
});

export const starRatings = theme =>
  css({
    '&.ant-rate': {
      fontSize: '1rem',
      lineHeight: '1rem',
      color: theme.yellow,
      marginRight: '0.625em',
      [Desktop]: {
        paddingLeft: '1em',
      },
    },
  });

export const ratingsCountLabel = theme =>
  css({
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: theme.gray300,
    padding: '0 0.1875em',
  });

export const dealDetailsWrapper = css({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: '1.25em',
  [Mobile]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 0,
  },
});

export const textFormat = css({
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: 400,
});

export const name = theme =>
  css({
    color: theme.gray200,
  });

export const userContainer = css({
  display: 'flex',
  alignItems: 'center',
  [Mobile]: {
    marginBottom: '0.75em',
    '& a': {
      textWrap: 'wrap',
    },
  },
});

export const merchantNameLbl = theme =>
  css({
    fontSize: '1rem',
    lineHeight: '1.375rem',
    color: theme.gray200,
  });

export const dealRatingWrapper = theme =>
  css({
    // paddingLeft: '0.5em',
    paddingRight: '1em',
    borderRight: `1px solid ${theme.gray500}`,
    borderLeft: `1px solid ${theme.gray500}`,
    [Mobile]: {
      borderRight: 'none',
      borderLeft: 'none',
      paddingLeft: '0',
    },
  });

export const dealEndDateWrapper = theme =>
  css({
    marginLeft: '0.75em',
    '.icon-Ends-on': {
      fontSize: '1rem',
      lineHeight: '1rem',
      marginRight: '0.5em',
    },
    '& label': {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: theme.gray100,
    },
    '& label:last-of-type': {
      fontWeight: 700,
    },
    [Mobile]: {
      marginLeft: 0,
      marginBottom: '1em',
    },
  });

export const imageInfoOverlay = css({
  display: 'none',
  fontSize: '0.8125rem',
  lineHight: 1,
  color: 'white',
  borderRadius: 3,
  backgroundColor: 'rgba(0,0,0,.5)',
  padding: '0.5em 0.8125em',
  position: 'absolute',
  width: '100%',
  bottom: 0,
  zIndex: 20,
  [Mobile]: {
    display: 'block',
  },
});

export const merchantOptions = theme =>
  css({
    backgroundColor: theme.gray600,
    border: `1px solid ${theme.gray500}`,
    borderRadius: 4,
    padding: '0.625em 0',
    alignItems: 'center',
    marginBottom: '2.375em',
  });

export const statusLabel = css({
  marginLeft: '1em',
});

export const merchantButtons = css({
  display: 'flex !important',
  justifyContent: 'flex-end',
  gap: '1em',

  '& button': {
    marginRight: '0.5em',
    width: '6rem',
  },
});

export const moreOptionsMoblie = css({
  border: 'none',
  background: 'transparent',
  width: '1.75rem !important',
  '& span': {
    fontSize: '1.75rem',
  },
});

export const popoverWrapper = theme =>
  css({
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '0px !important',
    backgroundColor: theme.white,
    borderRadius: 4,
    '& .ant-popover-inner-content': {
      padding: 0,
    },
    '& button': {
      display: 'block',
      border: 'none',
      background: theme.white,
      padding: '0.5625em 1em',
      fontSize: '0.75rem',
    },
  });

export const facebookButton = theme =>
  css({
    backgroundColor: `${theme.fb_blue} !important`,
    borderRadius: 3,
    color: `${theme.white} !important`,
    fontSize: '0.625rem !important',
    padding: '0.25em 0.5em !important',
    height: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      display: 'inline-block',
      width: '0.75rem',
      height: '0.7rem',
      borderRadius: '12px',
      backgroundColor: theme.white,
      color: theme.fb_blue,
      marginRight: '0.25em',
    },
    '&:hover': {
      opacity: 1,
      backgroundColor: theme.fb_blue,
      color: theme.white,
    },
  });

export const statusTag = theme =>
  css({
    backgroundColor: theme.red,
    textTransform: 'uppercase',
    color: theme.white,
    fontSize: '0.6875rem',
    fontWeight: 500,
    padding: '0.1875em',
    borderRadius: '3px',
  });

export const availableBvPoints = theme =>
  css({
    fontWeight: 500,
    padding: '0 1em',
    borderLeft: `1px solid ${theme.gray500}`,
  });
