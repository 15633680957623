import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslations } from '@veraio/strank';
import { printPrice, useSettings, EXTERNAL_PAYMENT_PROVIDER } from '@oneecosystem/dealshaker-core';
import { isNil } from '@veraio/core';
import QRCode from 'qrcode';
import { CopyText, CountdownTimer } from 'components/UIExternal';
import { setError, getOrderExternalPayment } from 'services';

export const OrderDetailsCryptoPaymentDetails = ({ orderId, fiatPrintPriceProps }) => {
  const { getText } = useTranslations();
  const [externalPayment, setExternalPayment] = useState();
  const settings = useSettings();

  useEffect(() => {
    // Overwrite the old value, because on switch of the selected order we want to re-initialize the state
    setExternalPayment();
    fetchExternalPayments();
  }, [orderId]);

  const fetchExternalPayments = async () => {
    const [res, err] = await getOrderExternalPayment(orderId);
    if (err) return setError(err);
    const coinPaymentCurrency = settings[EXTERNAL_PAYMENT_PROVIDER.COINPAYMENTS_CURRENCY] ?? 'USDT';
    const externalPaymentInfo = `${coinPaymentCurrency}:${res?.paymentAddress}?amount=${res?.amountRequested}`;
    const qrCodeBase64DataUrl = await QRCode.toDataURL(externalPaymentInfo);
    setExternalPayment({ ...res, qrCodeBase64DataUrl });
  };

  if (isNil(externalPayment)) return null;

  return (
    <section className="order-details-payment-crypto-container" data-id="order-details-crypto-payment-status">
      <div className="order-details-payment-crypto-headline">
        <h4>{getText('paymentRequestFor', { amount: printPrice(fiatPrintPriceProps) })}</h4>
        <CountdownTimer endDateTime={moment.utc((externalPayment?.timeExpires ?? 0) * 1000)} />
      </div>
      <div className="order-details-payment-crypto-details">
        <div className="order-details-payment-crypto-details-item">
          <img src={externalPayment?.qrCodeBase64DataUrl} width={150} alt="" />
        </div>
        <h2>{getText('or').toUpperCase()}</h2>
        <div className="order-details-payment-crypto-details-item">
          <h5>{getText('paymentAddress')}</h5>
          <CopyText successMessage={getText('paymentAddressCopiedToClipboard')}>
            {externalPayment?.paymentAddress}
          </CopyText>
        </div>
      </div>
    </section>
  );
};

OrderDetailsCryptoPaymentDetails.propTypes = {
  orderId: PropTypes.number,
  fiatPrintPriceProps: PropTypes.object,
};
