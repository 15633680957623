import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getOrderExternalPayment, setError } from 'services';
import config from 'config/envConfig';
import { STRIPE_PAYMENT_STATUS } from 'enums/stripe';
import PaymentForm from './PaymentForm';
import ExpiredPayment from './ExpiredPayment';

const Stripe = () => {
  const { orderId } = useParams();
  const stripePromise = loadStripe(config.stripePublishableKey);

  const [cardElementOptions, setCardElementOptions] = useState(null);
  const [externalPaymentInfo, setExternalPaymentInfo] = useState(null);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    fetchExternalPayments();
  }, [orderId]);

  const fetchExternalPayments = async () => {
    const [res, err] = await getOrderExternalPayment(orderId);
    if (err) return setError(err);

    setExternalPaymentInfo(res);

    if (res?.statusText !== STRIPE_PAYMENT_STATUS.CANCELLED) {
      const expiresAfter = res?.timeExpires * 1000 - Date.now();
      if (expiresAfter <= 0) setIsExpired(true);
      else {
        setTimeout(() => setIsExpired(true), expiresAfter);

        setCardElementOptions({
          clientSecret: res?.paymentAddress,
          appearance: {
            theme: 'stripe',
          },
        });
      }
    } else setIsExpired(true);
  };

  return <>
    {isExpired ? (<ExpiredPayment />) : (cardElementOptions !== null && (
      <Elements stripe={stripePromise} options={cardElementOptions}>
        <PaymentForm orderId={orderId} externalPaymentInfo={externalPaymentInfo} />
      </Elements>
      )
    )}
    </>;
};

export default Stripe;
