import { useEffect } from 'react';
import { isNil } from '@veraio/core';
import {
  useLocations,
  useCurrencies,
  useSettings,
  getBooleanSettings,
  getJsonSettings,
  getNumberSettings,
  EXTERNAL_PAYMENT_PROVIDER,
  PAYMENT_METHOD,
} from '@oneecosystem/dealshaker-core';
import { useUser } from 'stores';

export const usePaymentMethods = (shoppingCart, onSubmit) => {
  useSettings();
  const { currentAccount } = useUser();
  const selectedCurrency = useCurrencies(currenciesStore => currenciesStore?.selectedCurrency);
  const geoLocation = useLocations(locationsState => locationsState.geoLocation);

  const isRestricted = geoLocation?.isCryptoRestricted;
  const { cashWalletBalance, coinWalletBalance, oespWalletBalance } = currentAccount;
  const { totalBasePriceFiat, totalLocalPriceFiat, totalPriceCrypto } = shoppingCart ?? {};
  const { cashPaymentMethodId, cryptoPaymentMethodId } = shoppingCart ?? {};
  const insufficientEuro = totalBasePriceFiat > cashWalletBalance;
  const insufficientOne = totalPriceCrypto > coinWalletBalance;
  const insufficientOesp = totalPriceCrypto > oespWalletBalance;
  const hasEuro = !!totalBasePriceFiat;

  const stripeCountries = getJsonSettings(EXTERNAL_PAYMENT_PROVIDER.STRIPE_COUNTRIES, []);
  const isActiveStripe =
    getBooleanSettings(EXTERNAL_PAYMENT_PROVIDER.STRIPE_PAYMENT_METHOD_ACTIVE, true) &&
    (!stripeCountries.length || stripeCountries.some(el => el === geoLocation?.countryId)) &&
    totalBasePriceFiat >= getNumberSettings(EXTERNAL_PAYMENT_PROVIDER.STRIPE_MINIMAL_AMOUNT, 0);

  const coinPaymentsCountries = getJsonSettings(EXTERNAL_PAYMENT_PROVIDER.COINPAYMENTS_COUNTRIES, []);
  const isActiveCoinPayments =
    getBooleanSettings(EXTERNAL_PAYMENT_PROVIDER.COINPAYMENTS_ACTIVE, true) &&
    (!coinPaymentsCountries.length || coinPaymentsCountries.some(el => el === geoLocation?.countryId));

  useEffect(() => {
    isNil(cashPaymentMethodId) && isNil(cryptoPaymentMethodId) && selectDefaultPayments();
  }, []);

  const selectDefaultPayments = () => {
    const defaultPaymentMethods = {
      cashPaymentMethod: PAYMENT_METHOD.CASH_WALLET,
      cryptoPaymentMethod: PAYMENT_METHOD.ONE_WALLET,
    };

    if (insufficientEuro) defaultPaymentMethods.cashPaymentMethod = PAYMENT_METHOD.CASH_ON_DELIVERY;
    if (isRestricted || insufficientOne) defaultPaymentMethods.cryptoPaymentMethod = PAYMENT_METHOD.OESP_WALLET;

    onSubmit(defaultPaymentMethods);
  };

  return {
    selectedCurrency,
    isRestricted,
    cashWalletBalance,
    coinWalletBalance,
    oespWalletBalance,
    hasEuro,
    totalLocalPriceFiat,
    totalPriceCrypto,
    insufficientEuro,
    insufficientOne,
    insufficientOesp,
    isActiveStripe,
    isActiveCoinPayments,
  };
};
