import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Skeleton, useUrlParams } from 'components';
import { toast } from 'react-hot-toast';
import { STRIPE_PAYMENT_STATUS } from 'enums/stripe';
import { Routes } from 'enums';
import { getText } from '@veraio/strank';
import { validatePaymentStatus } from 'utils/cardPayment';

const PaymentConfirmation = () => {
  const { orderId,  } = useParams();
  const history = useHistory();
  const { queryParams } = useUrlParams();

  const redirectStatus = queryParams.redirect_status;

  useEffect(() => {
    if (redirectStatus === STRIPE_PAYMENT_STATUS.FAILED) {
      history.push({ pathname: Routes.payOrderWithCard.replace(':orderId', orderId) });
      toast.error(getText('paymentFailedTryAgain'));
    } else if (redirectStatus === STRIPE_PAYMENT_STATUS.SUCCEEDED) {
      validatePaymentStatus(orderId, () => {
        toast.success(getText('successfulPayment'));
        history.push(`${Routes.myProfile}${Routes.orders}`);
      });
    }
  }, [orderId]);

  return (
    <Skeleton height="30rem" margin="1.5em 0"/>
  );
};

export default PaymentConfirmation;
