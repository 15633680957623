import React, { useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { getText } from '@veraio/strank';
import { STRIPE_PAYMENT_STATUS } from 'enums/stripe';
import { CountdownTimer } from 'components';
import { toUtc } from 'utils';
import { validatePaymentStatus } from 'utils/cardPayment';
import { Routes } from 'enums';
import {
  cardElementContainer, cardElementSubTitle,
  cardElementTitle,
  paymentInfoContainer,
  payNow,
  processing,
  subTitleHolder,
} from './styles';

const PaymentForm = props => {
  const { orderId, externalPaymentInfo } = props;

  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const [isProcessing, setIsProcessing] = useState(false);
  const amount = parseFloat(externalPaymentInfo.amountRequested.replace(',', '.')).toFixed(2);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setIsProcessing(true);


    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/order/${orderId}/card-payment-confirmation`,
      },
    });

    if (error) {
      toast.error(error.message);
      setIsProcessing(false);
    } else if (paymentIntent.status ===  STRIPE_PAYMENT_STATUS.SUCCEEDED) {
      validatePaymentStatus(orderId, () => {
        toast.success(getText('successfulPayment'));
        history.push(`${Routes.myProfile}${Routes.orders}`);
      });
    }
  };

  return (
    <section className="content-container">
      <h3 className={cardElementTitle}>{getText('debitOrCreditCard')}</h3>
      <Row justify="center" align="middle">
        <Col span={18} lg={12}>
          <div className={cardElementContainer}>
            <div className={subTitleHolder}>
              <h5 className={cardElementSubTitle}>{getText('paymentIsProcessedBy')}</h5>
              <a href="https://stripe.com" target="_blank" rel="noreferrer">
                <img src="https://oneecosystem.blob.core.windows.net/dealshaker/apps/images/stripe-logo.png" alt="stripe" height={40} />
              </a>
            </div>
            <p>{getText('yourInfoIsSecuredByStripe')}</p>
            <div className={paymentInfoContainer}>
              <span>
                {getText('amount')}:{' '}
                <b>
                  {amount} {externalPaymentInfo.currency}
                </b>
              </span>
              <div>
                <CountdownTimer endDateTime={toUtc(externalPaymentInfo.timeExpires * 1000)} />
              </div>
            </div>
            <PaymentElement />

            {isProcessing ? (
              <p className={processing}>{getText('processing')}...</p>
            ) : (
              <Button type="primary" className={payNow} disabled={!stripe} onClick={handleSubmit}>
                {getText('payNow')}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default PaymentForm;
