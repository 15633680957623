import { PAYMENT_METHOD_INVERSE, PAYMENT_METHOD } from '@oneecosystem/dealshaker-core';

export const paymentTypeBoxesConfig = {
  [PAYMENT_METHOD.CASH_ON_DELIVERY]: {
    icon: 'las la-wallet',
    title: PAYMENT_METHOD_INVERSE[PAYMENT_METHOD.CASH_ON_DELIVERY],
    subTitle: 'toBePaidByUserOnDelivery',
  },
  [PAYMENT_METHOD.BANK_TRANSFER]: {
    icon: 'las la-university',
    title: PAYMENT_METHOD_INVERSE[PAYMENT_METHOD.BANK_TRANSFER],
    subTitle: 'toBePaidByUserThroughBank',
  },
  [PAYMENT_METHOD.CASH_WALLET]: {
    icon: 'las la-wallet',
    title: PAYMENT_METHOD_INVERSE[PAYMENT_METHOD.CASH_WALLET],
    subTitle: 'paidByDuringCheckout',
  },
  [PAYMENT_METHOD.ONE_WALLET]: {
    icon: 'las la-wallet',
    title: PAYMENT_METHOD_INVERSE[PAYMENT_METHOD.ONE_WALLET],
    subTitle: 'paidByDuringCheckout',
  },
  [PAYMENT_METHOD.OESP_WALLET]: {
    icon: 'las la-coins',
    title: PAYMENT_METHOD_INVERSE[PAYMENT_METHOD.OESP_WALLET],
    subTitle: 'paidByDuringCheckout',
  },
  [PAYMENT_METHOD.COIN_PAYMENTS]: {
    icon: 'las la-wallet',
    title: PAYMENT_METHOD_INVERSE[PAYMENT_METHOD.COIN_PAYMENTS],
    subTitle: 'paidByCoinpayments',
  },
  [PAYMENT_METHOD.STRIPE]: {
    icon: 'las la-credit-card',
    title: 'debitOrCreditCard',
    subTitle: 'paidByBankIssuedCard',
  },
};
