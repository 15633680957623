import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const galleryWrapper = css({
  '& .image-gallery-thumbnails .image-gallery-thumbnails-container': {
    display: 'flex',
    flexDirection: 'row',
  },
  '& .image-gallery-thumbnails-wrapper.bottom': {
    margin: '1em 0',
    maxWidth: '70%',

    [Mobile]: {
      display: 'none',
    },
  },
  '& .image-gallery-thumbnails': {
    display: 'flex',
  },
  '& .image-gallery-thumbnail': {
    border: '1px solid transparent',
    marginRight: '0.5em',
  },
  '& .image-gallery-thumbnail .image-gallery-thumbnail-image': {
    maxWidth: '4rem',
    maxHeight: '4rem',
    aspectRatio: '1 / 1',
    objectFit: 'cover',
    borderRadius: 4,
  },
  '& .image-gallery-thumbnail.active, & .image-gallery-thumbnail:focus, & .image-gallery-thumbnail:hover': {
    border: '1px solid transparent',
  },
  '& .image-gallery-thumbnail .image-gallery-thumbnail-inner': {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .image-gallery-slide': {
    width: '100%',
    marginTop: '2em',
  },
  '& .image-gallery-slide div': {
    width: '100%',
    padding: 0,
  },
  '& .image-gallery-slides': {
    display: 'flex',
    borderRadius: 4,
  },
  '& .image-gallery-image': {
    maxWidth: '25rem',
    maxHeight: '25rem',
    aspectRatio: '1 / 1',
    objectFit: 'contain',
  },
  '& .image-gallery-slide.center': {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
