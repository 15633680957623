import React from 'react';
import { useTranslations } from '@veraio/strank';
import { Button, Image } from 'components/UIExternal';
import { expiredImage, expiredPaymentContainer, paragraph } from './styles';

const ExpiredPayment = () => {
  const { getText } = useTranslations();

  return (
    <div className={expiredPaymentContainer}>
      <Image
        img
        src="https://oneecosystem.blob.core.windows.net/dealshaker/apps/images/importantLaptopMessage.svg"
        className={expiredImage}
        alt="Payment expired"
      />
      <h4>{getText('paymentCodeExpired')}</h4>
      <p className={paragraph}>{getText('paymentCodeExpiredText')}</p>
      <p className={paragraph}>
        <Button type="primary" linkTo="/">
          {getText('goToHomepage')}
        </Button>
      </p>
    </div>
  );
};

export default ExpiredPayment;