import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const titleWrapper = css({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: '0.5em 1em',
  marginBottom: '1em',
  '& div': {
    [Mobile]: {
      width: 'auto',
    },
  },
  '& p': {
    fontSize: '1rem',
  },
});

export const loadMoreBtn = css({
  marginBottom: '1.875em',
  [Mobile]: {
    margin: 'auto',
    width: '100%',
    '& span': {
      margin: 'auto',
    },
  },
});

export const backButton = theme =>
  css({
    color: theme.gray100,
    fontSize: '1.1875rem',
    fontWeight: 700,
    paddingLeft: '0',
  });

export const noDealsContainer = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [Mobile]: {
      alignItems: 'flex-start',
    },
    '& div': {
      textAlign: 'center',
    },
    '& img': {
      marginBottom: '1.375em',
    },
    '& label': {
      color: theme.gray200,
      display: 'block',
    },
    '& button': {
      border: 'none',
      background: 'transparent',
      color: theme.blue2,
      textDecoration: 'underline',
      marginTop: '0.5em',
      fontSize: '0.8125rem',
    },
    '& button:hover': {
      color: theme.blue,
    },
  });
