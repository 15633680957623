import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { useAddresses } from '@oneecosystem/dealshaker-core';
import { Button, CheckButton } from 'components/UIExternal';
import { CheckoutBusiness, ShoppingCartItem } from 'components/screens';
import { Routes } from 'enums';
import { businessCartStyles, actionButtons, roundedContainer } from './styles';

const CheckoutSummary = props => {
  const { shoppingCart, onSubmit, onBack } = props;
  const theme = useTheme();
  const { getText } = useTranslations();
  const userAddresses = useAddresses();
  const [tncChecked, setTncChecked] = useState(false);
  const [tncError, setTncError] = useState(false);

  const matchAddress = (business, item) => {
    const { selectedBusinessAddressId: bAddr, selectedUserAddressId: uAddr } = item ?? business.items.at(0);
    const match = bAddr
      ? business.businessAddresses.find(el => el.id === bAddr)
      : userAddresses.find(el => el.id === uAddr);
    return { ...match, businessAddress: bAddr };
  };

  const toggleTnCCheckbox = () => setTncChecked(!tncChecked);

  const handleSubmit = () => (tncChecked ? onSubmit() : setTncError(true));

  return (
    <>
      {shoppingCart?.businesses?.map(business => (
        <CheckoutBusiness
          key={business?.businessId}
          business={business}
          address={matchAddress(business)}
          className={businessCartStyles(theme)}
        >
          {business.items?.map(item => (
            <ShoppingCartItem item={item} key={item.dealId} />
          ))}
        </CheckoutBusiness>
      ))}

      <div className={roundedContainer(theme, tncError)}>
        <CheckButton size="1.5rem" colorChecked="primary_blue" colorUnChecked="red_dark" onChange={toggleTnCCheckbox}>
          <span>
            {' '}
            * {getText('iAcceptDealshaker')}
            <Button type="link" small linkTo={Routes.termsAndConditional} target="_blank">
              {getText('termsAndConditions')}
            </Button>
          </span>
        </CheckButton>
      </div>

      <div className={actionButtons}>
        <Button type="default" small onClick={onBack}>
          {getText('back')}
        </Button>
        <Button small onClick={handleSubmit}>
          {getText('submit')}
        </Button>
      </div>
    </>
  );
};

CheckoutSummary.propTypes = {
  shoppingCart: PropTypes.object,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CheckoutSummary;
