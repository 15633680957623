import React, { useState, useEffect } from 'react';
import { useTheme } from 'emotion-theming';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Col, Row, Spin } from 'antd';
import { BorderlessShadowlessGrayBox, StatusProgressIcon } from 'components/ui';
import { Button } from 'components/UIExternal';
import { Routes } from 'enums';
import { getCreateDealStepData, getCurrentWizardStep } from 'services/api/dealsService';
import useError from 'services/errorHandling/useError';
import { mr } from 'assets/css/globalCSS';
import StepsComponent from './components/StepsComponent/StepsComponent';
import StepOne from './components/stepOne';
import StepTwo from './components/stepTwo';
import StepThree from './components/stepThree';
import StepFour from './components/stepFour';
import StepFive from './components/stepFive';
import { heading, paragraph, submittedDealWrapper } from './styles';

const AddDealPage = () => {
  const theme = useTheme();
  const history = useHistory();
  const { setError } = useError();
  const { getText } = useTranslations();
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [manualStep, setManualStep] = useState(0);
  const [stepProgress, setStepProgress] = useState(1);
  const [lastUpdateTime, setLastUpdateTime] = useState('');
  const [stepOneData, setStepOneData] = useState({});
  const [stepTwoData, setStepTwoData] = useState({});
  const [minOneCoinPercent, setMinOneCoinPercent] = useState(0);
  const [stepThreeData, setStepThreeData] = useState({});
  const [stepFourData, setStepFourData] = useState({});
  const [stepFiveData, setStepFiveData] = useState({});
  const [isDealSubmitted, setIsDealSubmitted] = useState(false);

  const dealId = params.dealId ? parseInt(params.dealId, 10) : null;
  const isInEditMode = !!dealId;
  const setStepFunctions = {
    1: setStepOneData,
    2: setStepTwoData,
    3: setStepThreeData,
    4: setStepFourData,
    5: setStepFiveData,
  };

  useEffect(() => {
    isInEditMode ? (manualStep ? handleCreateStepData(manualStep) : handleWizardStep()) : setCurrentStep(1);
    window.scrollTo(0, 0);
  }, [manualStep, isInEditMode]);

  const handleWizardStep = async () => {
    const [res, err] = await getCurrentWizardStep(dealId);
    err && setError(err);
    res < 5 && handleCreateStepData(res + 1);
    res === 5 && handleCreateStepData(1);
  };

  const handleCreateStepData = async step => {
    const [res, err] = await getCreateDealStepData(step, dealId);
    err && history.push('/merchant-office/my-deals');
    setStepFunctions[step](res);
    setStepProgress(res?.dealWizardStep);
    setCurrentStep(step);
    setLastUpdateTime(res?.lastUpdate);
    res.minOneCoinPercent && setMinOneCoinPercent(res.minOneCoinPercent);
  };

  const mainStepContent = {
    1: (
      <StepOne
        stepOneData={stepOneData}
        setStepOneData={setStepOneData}
        dealId={dealId}
        currentStep={currentStep}
        setManualStep={setManualStep}
      />
    ),
    2: (
      <StepTwo
        stepTwoData={stepTwoData}
        currentStep={currentStep}
        setManualStep={setManualStep}
        dealId={dealId}
        minOneCoinPercent={minOneCoinPercent}
      />
    ),
    3: (
      <StepThree
        stepThreeData={stepThreeData}
        setStepThreeData={setStepThreeData}
        currentStep={currentStep}
        setManualStep={setManualStep}
        dealId={dealId}
      />
    ),
    4: (
      <StepFour
        stepFourData={stepFourData}
        handleCreateStepData={handleCreateStepData}
        currentStep={currentStep}
        setManualStep={setManualStep}
        dealId={dealId}
      />
    ),
    5: (
      <StepFive
        stepFiveData={stepFiveData}
        currentStep={currentStep}
        setManualStep={setManualStep}
        dealId={dealId}
        setIsDealSubmitted={setIsDealSubmitted}
      />
    ),
  };

  const onAddAnotherDealHandler = () => {
    setIsDealSubmitted(false);
    setStepOneData({});
    setStepTwoData({});
    setStepThreeData({});
    setStepFourData({});
    setStepFiveData({});
    setStepProgress(1);
    setManualStep(1);
    history.push(Routes.addDealPage);
  };

  const dealSubmittedContent = (
    <Row>
      <Col lg={{ offset: 6, span: 11 }} span={24}>
        <BorderlessShadowlessGrayBox theme={theme} className={submittedDealWrapper(theme)}>
          <StatusProgressIcon customClassName="success-img" status="success" />
          <h2>{getText('yourDealWasSendForApproval')}</h2>
          <label>{getText('moderatorReviewFree')}</label>
          <div className="flex submit-deal-btns">
            <Button type="secondary" small onClick={onAddAnotherDealHandler} className={mr({ lg: 10 })}>
              {getText('addAnotherDeal')}
            </Button>
            <Button type="primary" small linkTo={`${Routes.merchantOffice}${Routes.myDeals}`}>
              {getText('goToDeals')}
            </Button>
          </div>
        </BorderlessShadowlessGrayBox>
      </Col>
    </Row>
  );
  const mainContent = (
    <>
      <Row>
        <Col lg={18} span={24} className="add-new-deal">
          <h2 className={heading(theme)}>{isInEditMode ? getText('editDeal') : getText('addNewDeal')}</h2>
          <p className={paragraph(theme)}>{getText('welcomeToDealWizard')}</p>
        </Col>
        <Col lg={6} span={0} />
      </Row>
      <Row gutter={[24, 24]}>
        <Col lg={4} span={24}>
          <StepsComponent
            currentStep={currentStep}
            stepProgress={stepProgress}
            setManualStep={setManualStep}
            lastUpdate={lastUpdateTime}
            dealId={dealId}
          />
        </Col>
        <Col lg={14} span={24}>
          {currentStep ? (
            mainStepContent[currentStep]
          ) : (
            <div className="content-container">
              <Spin size="large" />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
  return <div className="content-container">{isDealSubmitted ? dealSubmittedContent : mainContent}</div>;
};

export default AddDealPage;
