import { css } from 'emotion';

export const paymentMethodsContainer = theme =>
  css({
    border: `1px solid ${theme.gray500}`,
    borderRadius: 12,
    marginBottom: '1em',
  });

export const paymentMethodTitle = css({
  padding: '1em 0',
});

export const paymentMethodContent = css({
  padding: '1em',
});

export const cardPaymentMethodContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const paymentMethodCheckbox = hasContent =>
  css({
    margin: '1em 0',
    alignItems: hasContent ? 'flex-start' : 'center',

    '& i': {
      fontSize: '1.5rem',
      borderRadius: '120px',
      marginTop: hasContent ? '0.2em' : 0,
    },
  });
