import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { isMobile } from 'react-device-detect';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import {
  chartTitle,
  chartContainer,
  pieChartContainer,
  chartLabel,
  legendItem,
  legendColorBox,
  legendWrapper,
  toolTipPieChart,
} from '../styles';

const CouponsPieChart = ({ data, total }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const pieSettings = {
    cx: isMobile ? '50%' : '70%',
    cy: '50%',
    innerRadius: isMobile ? 50 : 65,
    outerRadius: isMobile ? 70 : 85,
  };

  const Colors = [theme.green, theme.gray500, theme.primary_yellow];

  const renderLegend = ({ payload }) => (
    <div className={!isMobile && legendWrapper}>
      {payload.map((entry, index) => (
        <div key={`item-${index}`} className={legendItem}>
          <span>
            <span className={legendColorBox(entry.color)} />
            <label>{entry.payload.key}</label>
          </span>
          <label> {entry.payload.value}</label>
        </div>
      ))}
    </div>
  );

  const CustomToolTip = ({ active, payload }) =>
    active &&
    payload &&
    payload.length && (
      <div className={toolTipPieChart(theme)}>
        <p>
          {payload[0]?.payload.key}: {payload[0]?.payload.value} ({payload[0]?.payload.percentage}%)
        </p>
      </div>
    );

  return (
    <div className={`${chartContainer} ${pieChartContainer}`}>
      <h6 className={chartTitle(theme)}>{getText('orderDeliveries')}</h6>
      <label className={chartLabel(theme)}>{total}</label>
      <ResponsiveContainer width="100%" aspect={isMobile ? 1.2 / 1.0 : 3.0 / 1.0}>
        <PieChart>
          <Pie
            dataKey="value"
            data={data}
            cx={pieSettings.cx}
            cy={pieSettings.cy}
            innerRadius={pieSettings.innerRadius}
            outerRadius={pieSettings.outerRadius}
            fill={theme.gray500}
          >
            {data.map((_entry, index) => (
              <Cell key={`cell-${index}`} fill={Colors[index % Colors.length]} />
            ))}
          </Pie>
          <Tooltip content={<CustomToolTip />} />
          {isMobile ? (
            <Legend verticalAlign="bottom" content={renderLegend} />
          ) : (
            <Legend layout="vetical" verticalAlign="top" align="left" content={renderLegend} />
          )}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

CouponsPieChart.propTypes = {
  data: PropTypes.array,
  total: PropTypes.number,
};

export default CouponsPieChart;
