import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import {
  printPrice,
  CRYPTO_CURRENCIES,
  DEFAULT_CRYPTO_CURRENCY,
  PAYMENT_METHODS_CURRENCIES,
  PAYMENT_METHOD,
} from '@oneecosystem/dealshaker-core';
import { Button, CheckButton } from 'components/UIExternal';
import { PaymentMethodWallet } from 'components/screens';
import { getPrecisionByExternalPaymentMethod } from 'utils';
import { usePaymentMethods } from './usePaymentMethods';
import { actionButtons } from '../styles';
import {
  paymentMethodsContainer,
  paymentMethodTitle,
  paymentMethodContent,
  paymentMethodCheckbox,
  cardPaymentMethodContainer,
} from './styles';

const basicCheckboxConfig = {
  size: '0.8rem',
  colorChecked: 'primary_blue',
  colorUnChecked: 'skyblue',
};

const CheckoutPaymentMethods = props => {
  const { shoppingCart, onBack, onSubmit, goNext } = props;
  const { getText } = useTranslations();
  const theme = useTheme();
  const {
    selectedCurrency,
    isRestricted,
    cashWalletBalance,
    coinWalletBalance,
    oespWalletBalance,
    hasEuro,
    totalLocalPriceFiat,
    totalPriceCrypto,
    insufficientEuro,
    insufficientOne,
    insufficientOesp,
    isActiveStripe,
    // isActiveCoinPayments,
  } = usePaymentMethods(shoppingCart, onSubmit);

  const cashPaymentsProps = type => ({
    ...basicCheckboxConfig,
    checked: shoppingCart?.cashPaymentMethodId === type,
    disabled: shoppingCart?.cashPaymentMethodId === type,
    onChange: () => onSubmit({ cashPaymentMethod: type }),
    className: paymentMethodCheckbox(type === PAYMENT_METHOD.CASH_WALLET),
  });

  const cryptoPaymentsProps = type => ({
    ...basicCheckboxConfig,
    checked: shoppingCart?.cryptoPaymentMethodId === type,
    onChange: () => onSubmit({ cryptoPaymentMethod: type }),
    className: paymentMethodCheckbox(true),
  });

  return (
    <>
      {hasEuro && (
        <>
          <h4 className={paymentMethodTitle}>
            {getText('payWithAmount', {
              amount: printPrice({
                fiat: totalLocalPriceFiat,
                fiatOriginCurrency: selectedCurrency?.code,
                fiatPrecision: getPrecisionByExternalPaymentMethod(selectedCurrency?.code),
                fiatOnly: false,
              }),
            })}
          </h4>
          <section className={paymentMethodsContainer(theme)} data-id="checkout-fiat-payment-methods">
            <div className={paymentMethodContent}>
              <div data-id="checkout-payment-method-cash-wallet">
                <CheckButton
                  {...cashPaymentsProps(PAYMENT_METHOD.CASH_WALLET)}
                  disabled={shoppingCart?.cashPaymentMethodId === PAYMENT_METHOD.CASH_WALLET || insufficientEuro}
                >
                  <PaymentMethodWallet
                    label={getText('cashWallet')}
                    balanceLabelPrintPriceConfig={{
                      fiat: cashWalletBalance,
                      fiatPrecision: getPrecisionByExternalPaymentMethod(selectedCurrency?.code),
                      fiatOnly: false,
                    }}
                    insufficientFunds={insufficientEuro}
                  />
                </CheckButton>
              </div>
              <hr />
              <div data-id="checkout-payment-method-cash-on-delivery">
                <CheckButton {...cashPaymentsProps(PAYMENT_METHOD.CASH_ON_DELIVERY)}>
                  <h5 className="title">{getText('cashAtDelivery')}</h5>
                </CheckButton>
              </div>
              <hr />
              <div data-id="checkout-payment-method-bank-transfer">
                <CheckButton {...cashPaymentsProps(PAYMENT_METHOD.BANK_TRANSFER)}>
                  <h5 className="title">{getText('bankTransfer')}</h5>
                </CheckButton>
              </div>
              {/* REMOVE TAG: HIDE USDT
              {isActiveCoinPayments && (
                <div data-id="checkout-payment-method-usdt">
                  <CheckButton {...cashPaymentsProps(PAYMENT_METHOD.COIN_PAYMENTS)}>
                    <h5 className="title">{getText('usdt')}</h5>&nbsp;
                    <img src="https://www.coinpayments.net/images/pub/buynow-grey.png" alt="usdt" />
                  </CheckButton>
                </div>
              )} */}
              {isActiveStripe && (
                <>
                  <hr />
                  <div data-id="checkout-payment-method-card" className={cardPaymentMethodContainer}>
                    <CheckButton {...cashPaymentsProps(PAYMENT_METHOD.STRIPE)}>
                      <h5 className="title">{getText('debitOrCreditCard')}</h5>&nbsp;
                    </CheckButton>
                    <img
                      src="https://oneecosystem.blob.core.windows.net/dealshaker/apps/images/stripe-logo.png"
                      alt="stripe"
                      height={40}
                    />
                  </div>
                </>
              )}
            </div>
          </section>
        </>
      )}

      <h4 className={paymentMethodTitle}>
        {getText('payWithAmount', {
          amount: printPrice({
            crypto: totalPriceCrypto,
            cryptoDesiredCurrency:
              PAYMENT_METHODS_CURRENCIES[shoppingCart.cryptoPaymentMethodId] ?? DEFAULT_CRYPTO_CURRENCY.code,
            fiatOnly: false,
          }),
        })}
      </h4>
      <section className={paymentMethodsContainer(theme)} data-id="checkout-crypto-payment-methods">
        <div className={paymentMethodContent}>
          {!isRestricted && (
            <div data-id="checkout-payment-method-one">
              <CheckButton
                {...cryptoPaymentsProps(PAYMENT_METHOD.ONE_WALLET)}
                disabled={shoppingCart?.cryptoPaymentMethodId === PAYMENT_METHOD.ONE_WALLET || insufficientOne}
              >
                <PaymentMethodWallet
                  label={getText('oneWallet')}
                  balanceLabelPrintPriceConfig={{
                    crypto: coinWalletBalance,
                    cryptoDesiredCurrency: CRYPTO_CURRENCIES.ONE.code,
                    fiatOnly: false,
                  }}
                  insufficientFunds={insufficientOne}
                />
              </CheckButton>
              <hr />
            </div>
          )}
          <div data-id="checkout-payment-method-oesp">
            <CheckButton
              {...cryptoPaymentsProps(PAYMENT_METHOD.OESP_WALLET)}
              disabled={shoppingCart?.cryptoPaymentMethodId === PAYMENT_METHOD.OESP_WALLET || insufficientOesp}
            >
              <PaymentMethodWallet
                label={getText('oespWallet')}
                balanceLabelPrintPriceConfig={{
                  crypto: oespWalletBalance,
                  cryptoDesiredCurrency: CRYPTO_CURRENCIES.OESP.code,
                  ignoreEmpty: true,
                  fiatOnly: false,
                }}
                insufficientFunds={insufficientOesp}
              />
            </CheckButton>
          </div>
        </div>
      </section>

      <div className={actionButtons}>
        <Button type="default" small onClick={onBack}>
          {getText('back')}
        </Button>
        <Button
          small
          onClick={() => goNext()}
          disabled={!shoppingCart?.cashPaymentMethodId || !shoppingCart?.cryptoPaymentMethodId}
        >
          {getText('next')}
        </Button>
      </div>
    </>
  );
};

CheckoutPaymentMethods.propTypes = {
  onBack: PropTypes.func,
  shoppingCart: PropTypes.object,
  onSubmit: PropTypes.func,
  goNext: PropTypes.func,
};

export default CheckoutPaymentMethods;
