import { css } from 'emotion';

export const businessCartStyles = theme =>
  css({
    backgroundColor: 'transparent',
    border: `1px solid ${theme.gray500}`,
  });

export const actionButtons = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: '2em',
});

export const roundedContainer = (theme, errorState = false) =>
  css({
    backgroundColor: theme.gray600,
    border: `1px solid ${errorState ? theme.red : theme.gray700}`,
    color: errorState ? theme.red : 'inherit',
    padding: '0.75em',
    marginBottom: '0.75rem',
    borderRadius: 8,
  });
