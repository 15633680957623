import React from 'react';
import PropTypes from 'prop-types';
import { Page, Document, Text, View, Image, PDFDownloadLink } from '@react-pdf/renderer';
import { getText } from '@veraio/strank';
import { DEFAULT_CRYPTO_CURRENCY, printPrice, PAYMENT_METHODS_CURRENCIES } from '@oneecosystem/dealshaker-core';
import { Button } from 'components/UIExternal';
import { useOrders, selectedOrderDeliveryAddress, selectedOrderPaymentDetails } from 'stores';
import { deliveryMethodInverse } from 'enums';
import blackDealshakerLogo from 'assets/images/blackDealshakerLogo.png';
import { formatDefaultDate } from 'utils/valueFormatter';
import { pdfStyles } from './styles';

const { headline, document, logo, orderSummary, grid } = pdfStyles;

const OrderSummaryPdf = () => {
  const order = useOrders(ordersState => ordersState?.selectedOrder);
  const { bankDetails } = order?.business ?? {};
  const { address } = selectedOrderDeliveryAddress();
  const { isFiatPaymentBank, isFiatPaymentPending } = selectedOrderPaymentDetails();
  const printPriceProps = {
    fiatOriginCurrency: order?.currencyCode,
    cryptoDesiredCurrency:
      PAYMENT_METHODS_CURRENCIES[order?.payment?.cryptoPaymentMethodId] ?? DEFAULT_CRYPTO_CURRENCY.code,
  };

  return (
    <PDFDownloadLink
      document={
        <Document>
          <Page>
            <View style={document}>
              <Image source={blackDealshakerLogo} style={logo} />
              <View style={orderSummary}>
                <Text style={headline}>{getText('orderedDeals')}</Text>
                <View style={orderSummary.orderNumber}>
                  <Text>
                    {getText('orderNumber')} {order?.id}
                  </Text>
                  <Text style={orderSummary.orderNumber.date}>{formatDefaultDate(order?.createdDate)}</Text>
                </View>
                {/* table */}
                <View style={orderSummary.table}>
                  {/* table header */}
                  <View style={orderSummary.table.header}>
                    {/* qty */}
                    <Text style={[orderSummary.table.header.element, orderSummary.table.header.quantity]}>Qty</Text>
                    {/* item name */}
                    <Text
                      style={[
                        orderSummary.table.header.element,
                        orderSummary.table.header.content,
                        orderSummary.table.header.element.leftEl,
                      ]}
                    >
                      {getText('itemName')}
                    </Text>
                    {/* merchant */}
                    <Text style={[orderSummary.table.header.element, orderSummary.table.header.content]}>
                      {getText('merchant')}
                    </Text>
                    {/* item price */}
                    <Text style={[orderSummary.table.header.element, orderSummary.table.header.content]}>
                      {getText('itemPrice')}
                    </Text>
                  </View>
                  {/* table body */}
                  {order?.orderItems?.map(deal => (
                    <View key={deal?.id} style={orderSummary.table.body.row}>
                      {/* qty */}
                      <Text style={[orderSummary.table.body.element, orderSummary.table.body.quantity]}>
                        {deal?.quantity}
                      </Text>
                      {/* item name */}
                      <Text
                        style={[
                          orderSummary.table.body.element,
                          orderSummary.table.body.content,
                          orderSummary.table.body.element.leftEl,
                        ]}
                      >
                        {deal?.title}
                      </Text>
                      {/* merchant */}
                      <Text style={[orderSummary.table.body.element, orderSummary.table.body.content]}>
                        {order?.business?.name}
                      </Text>
                      {/* item price */}
                      <Text style={[orderSummary.table.body.element, orderSummary.table.body.content]}>
                        {printPrice({
                          ...printPriceProps,
                          fiat: deal?.priceFiat,
                          fiatDesiredCurrency: deal?.currencyCode,
                          crypto: deal?.priceCrypto,
                          fiatOnly: false,
                        })}
                      </Text>
                    </View>
                  ))}
                </View>
                <View style={orderSummary.total}>
                  <Text>
                    {`${getText('total')}: `}
                    {printPrice({
                      ...printPriceProps,
                      fiat: order?.totalPriceFiat,
                      fiatDesiredCurrency: order?.currencyCode,
                      crypto: order?.totalPriceCrypto,
                      fiatOnly: false,
                    })}
                  </Text>
                </View>
              </View>
              <View>
                <Text style={headline}>{getText('delivery')}</Text>
                <View style={grid.row}>
                  <View>
                    <Text style={grid.title}>{getText('type')}:</Text>
                    <Text style={grid.content}>
                      {getText(deliveryMethodInverse[order?.delivery?.deliveryMethodId]) ?? 'N/A'}
                    </Text>
                  </View>
                </View>
                <View style={grid.row}>
                  <View>
                    <Text style={grid.title}>{getText('address')}:</Text>
                    <Text style={grid.content}>{address ?? 'N/A'}</Text>
                  </View>
                </View>
              </View>
              {isFiatPaymentBank && isFiatPaymentPending && !!order?.totalPriceFiat && (
                <View style={grid}>
                  <Text style={headline}>{getText('bankDetails')}</Text>
                  <View style={grid.row}>
                    <View style={grid.element}>
                      <Text style={grid.title}>{getText('merchantFullName')}:</Text>
                      <View>
                        <Text style={grid.content}>{bankDetails?.fullName ?? 'N/A'}</Text>
                      </View>
                    </View>
                    <View style={grid.element}>
                      <Text style={grid.title}>{getText('bankName')}:</Text>
                      <View>
                        <Text style={grid.content}>{bankDetails?.bankName ?? 'N/A'}</Text>
                      </View>
                    </View>
                    <View style={grid.element}>
                      <Text style={grid.title}>{getText('bankAccountNumber')}:</Text>
                      <View>
                        <Text style={grid.content}>{bankDetails?.iban ?? 'N/A'}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={grid.row}>
                    <View style={grid.element}>
                      <Text style={grid.title}>{getText('bicSwiftCode')}:</Text>
                      <View>
                        <Text style={grid.content}>{bankDetails?.bic ?? 'N/A'}</Text>
                      </View>
                    </View>
                    <View style={grid.element}>
                      <Text style={grid.title}>{getText('address')}:</Text>
                      <View>
                        <Text style={grid.content}>{bankDetails?.address ?? 'N/A'}</Text>
                      </View>
                    </View>
                    <View style={grid.element}>
                      <Text style={grid.title}>{getText('preferredCurrency')}:</Text>
                      <View>
                        <Text style={grid.content}>{bankDetails?.currency ?? 'N/A'}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              )}
            </View>
          </Page>
        </Document>
      }
      fileName="order-summary"
    >
      <Button type="secondary" leftIcon={{ iconName: 'las la-download', size: 18 }}>
        PDF
      </Button>
    </PDFDownloadLink>
  );
};

OrderSummaryPdf.propTypes = {
  business: PropTypes.object,
  bankDetailsData: PropTypes.object,
  geoLocation: PropTypes.object,
  getText: PropTypes.func,
  order: PropTypes.object,
  currency: PropTypes.object,
  getCurrencyPrice: PropTypes.func,
};

export default OrderSummaryPdf;
