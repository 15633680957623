import { css } from 'emotion';

export const expiredPaymentContainer = css({
  textAlign: 'center',
  padding: '4rem 0',
});

export const expiredImage = css({
  margin: '2rem auto',
  maxWidth: 300,
});

export const paragraph = css({
  marginTop: '1rem',
});