import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const sectionHeader = css({
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  margin: '1em 0',
  '& > a': {
    marginBottom: '1em',
  },
  '& > *': {
    gap: '1em',
  },
});

export const sectionTitle = css({
  fontWeight: 700,
  fontSize: '1.4375rem',
  margin: '1.5em 0px 1em 0px',
});

export const businessSelectWrapper = css({
  '& .ant-select.ant-select-single': {
    marginBottom: '0.9375em',
  },
  '& .ant-select-selector': {
    borderRadius: '36px !important',
    width: '15.625rem !important',
  },
});

export const datePickersContainer = theme =>
  css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '0.5em',
    '& label': {
      color: theme.gray300,
      fontSize: '0.75rem',
    },
  });

export const overviewBox = theme =>
  css({
    border: `1px solid ${theme.gray500}`,
    padding: '1em',
    borderRadius: '3px',
    '& .overview-box-title': {
      fontWeight: 500,
      [Mobile]: {
        fontSize: '1.875rem',
      },
    },
    '& .overview-box-description': {
      fontSize: '0.8125rem',
      lineHeight: '1.25rem',
      color: theme.gray300,
    },
  });

export const borderBox = theme =>
  css({
    border: `1px solid ${theme.gray500}`,
    borderRadius: '2px',
    marginBottom: '1.25em',
  });

export const chartContainer = css({
  padding: '1em 1.875em',
});

export const pieChartContainer = css({
  padding: '0.6875em',
  '& .recharts-default-legend': {
    textAlign: 'left !important',
    fontSize: '0.75rem',
    [Mobile]: {
      fontSize: '0.625rem',
    },
  },
});

// Legend styles
export const legendColorBox = color =>
  css({
    display: 'inline-block',
    width: '0.6875rem',
    height: '0.6875rem',
    border: `1px solid ${color}`,
    borderRadius: '2px',
    backgroundColor: color,
    marginRight: '0.625em',
  });

export const legendItem = css({
  marginBottom: '1em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& label': {
    fontSize: '0.75rem',
    textTransform: 'upperCase',
    [Mobile]: {
      fontSize: '0.625rem',
    },
  },
  '& > span': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const legendWrapper = css({
  minWidth: '8.75rem',
});

export const mr30 = css({
  marginRight: '1.875em',
});

export const flexEnd = css({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  [Mobile]: {
    justifyContent: 'flex-start',
    marginBottom: '1.25em',
  },
});

export const chartTitle = theme =>
  css({
    color: theme.gray100,
    fontSize: '0.8125rem',
    fontWeight: 500,
  });

export const chartLabel = theme =>
  css({
    color: theme.gray100,
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: '2.875rem',
  });

export const chartsFiltersWrapper = css({
  marginTop: '2.5em',
  marginBottom: '1.25em',
  '& > div': {
    display: 'flex',
    gap: '1em',
  },
  '& label': {
    fontSize: '1rem',
  },
  '& .ant-select': {
    fontSize: '1rem',
    fontWeight: 500,
  },
  '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector': {
    border: 'none',
    boxShadow: 'none',
  },
  '& .ant-select-single:not(.ant-select-customize-input) .ant-select-selector': {
    paddingLeft: 0,
    backgroundColor: 'transparent',
  },
});

export const dropdownContentWrapper = css({ minWidth: 'fit-content !important' });

export const dailySalesTitleWrapper = theme =>
  css({
    marginTop: '1.25em',
    marginBottom: '1.0625em',
    '& > div': {
      marginBottom: '1em',
    },
    '& > div:nth-child(2)': {
      '& label': {
        margin: '0 1em',
      },
      '@media (max-width: 350px)': {
        '& label': {
          display: 'block',
          margin: '0.5em 0',
        },
      },
    },
    '& label:first-child': {
      fontSize: '1.5rem',
      fontWeight: 500,
      flexGrow: 1,
    },
    '& label:not(:first-child)': {
      fontSize: '0.75rem',
      [Mobile]: { marginRight: '0.25em', marginLeft: '0.25em' },
      marginRight: '0.5em',
      marginLeft: '0.5em',
      color: theme.gray300,
    },
    '& button': {
      border: 'none',
      background: 'transparent',
      fontSize: '1rem',
      color: theme.blue2,
    },
    '& button:hover': {
      color: theme.blue,
    },
    [Mobile]: {
      '& label:first-child': {
        marginBottom: '0.9375em',
      },
      '& button': {
        marginTop: '0.9375em',
        width: '100%',
      },
    },
  });

export const soldLabel = css({
  marginLeft: 'auto',
  marginRight: '1.875em',
  fontSize: '0.75rem',
});

export const dailySalesDealCard = css({
  marginBottom: '1.0625em',

  '& .deal-image': {
    aspectRatio: '1 / 1',
    objectFit: 'cover',
    borderRadius: '3px',
  },
});

export const dailySalesDealTitle = css({
  marginTop: '0.3125em',
  fontSize: '1.25rem',
  fontWeight: 500,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
});

export const starRatings = theme =>
  css({
    marginTop: '0.625em !important',
    '&.ant-rate': {
      fontSize: '1.125rem',
      color: theme.gray300,
    },
  });

export const ratingsCountLabel = css({
  fontSize: '0.875rem',
  marginLeft: '0.3125em',
});

export const soldCountLabelWrapper = css({
  display: 'flex !important',
});

export const soldCountDesktopLabel = css({
  fontSize: '1.5rem',
  alignSelf: 'center',
});

export const soldCountMobileLabel = css({
  display: 'block',
  marginTop: '0.625em',
  fontSize: '1.125rem',
});

export const toolTipPieChart = theme =>
  css({
    background: `${theme.gray800}`,
    padding: '1em',
    fontWeight: 'bold',
  });
