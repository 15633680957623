import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { printPrice, useCurrencies } from '@oneecosystem/dealshaker-core';
import { PDFDownloadLink } from '@react-pdf/renderer';
import FeatherIcon from 'feather-icons-react';
import QRCode from 'qrcode';
import { useTheme } from 'emotion-theming';
import moment from 'moment';
import { Dropdown, Button } from 'components/UIExternal';
import { qrCodes } from 'enums/MerchantOffice';
import { mt, mr } from 'assets/css/globalCSS';
import { qrBlock, dropDownStyles, qrColorThemePreview } from './styles';
import DealQRTemplate from './DealQRPdf';

const DealQRCodeBlock = ({ link, deal, dealTitle, businessName }) => {
  useCurrencies(currenciesState => currenciesState?.selectedCurrency);
  const { getText } = useTranslations();
  const theme = useTheme();
  const [qrCode, setQrCode] = useState({ src: null, ref: null, version: 1 });
  const refImg = useRef();
  const { price, priceFiat, priceCrypto } = deal;

  useEffect(() => {
    generateQR(link);
  }, [link]);

  const generateQR = async () => {
    const res = await QRCode.toDataURL(link);
    res && setQrCode(prev => ({ ...prev, src: res }));
  };

  return (
    <div className={qrBlock}>
      <p>{getText('qrCodeDeal')}</p>
      <img src={qrCode.src} alt="qr" ref={refImg} />
      <div className={qrColorThemePreview(theme, qrCode.version)} />
      <Dropdown
        small
        placeholder={getText('selectDesignVersion')}
        options={qrCodes(getText)}
        noClear
        value={qrCode.version}
        onChange={e => setQrCode(prev => ({ ...prev, version: e.value }))}
        className={(mt({ lg: 10, md: 10, sm: 5 }), dropDownStyles)}
      />
      <PDFDownloadLink
        document={
          <DealQRTemplate
            qrCodeImg={qrCode.src}
            version={qrCode.version}
            dealTitle={dealTitle}
            businessName={businessName}
            getPrice={printPrice({
              fiat: priceFiat,
              fiatOriginCurrency: deal?.currencyCode,
              fiatCrypto: priceCrypto,
              fiatLabelSign: 'code',
              percentage: deal?.percentRatio,
              total: price,
            })}
          />
        }
        fileName={`Deal code ${moment().format('yyyy-mm-DD')}`}
        className={mt({ lg: 5, md: 5, sm: 5 })}
      >
        {() => (
          <Button type="secondary" small fullWidth>
            <FeatherIcon icon="download" size={15} className={mr({ lg: 5, md: 5 })} />
            {getText('downloadQr')}
          </Button>
        )}
      </PDFDownloadLink>
    </div>
  );
};

DealQRCodeBlock.propTypes = {
  deal: PropTypes.object,
  link: PropTypes.string,
  dealTitle: PropTypes.string,
  businessName: PropTypes.string,
};

export default DealQRCodeBlock;
