import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Dropdown } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { Row } from '../Grid';
import { usePagination } from './usePagination';
import { paginationContainer, shownResultsIndicator, paginationButton, pageSizeDropdown } from './styles';

export const Pagination = forwardRef((props, ref) => {
  const {
    pageSizes,
    withPageDropdown = false,
    justify,
    showArrows = false,
    showCurrentIndex = false,
    dropDownListOptionsOnTop = false,
  } = props;

  const {
    totalResults,
    totalPages,
    pageNumber,
    pageSize,
    handlePageChange,
    handlePageSizeChange,
    defaultPageSizes,
  } = usePagination({
    ...props,
    ref,
  });

  const theme = useTheme();
  const noResults = totalPages === 0;
  const needEllipses = totalPages > 5;

  const arrowsButton = (disabled, type) =>
    showArrows && (
      <button
        className={paginationButton(disabled, false, theme)}
        {...(!disabled && {
          onClick: () => handlePageChange(pageNumber - (type === 'left' ? 1 : -1)),
        })}
      >
        <Icon material size={22} iconName={type === 'left' ? 'chevron_left' : 'chevron_right'} />
      </button>
    );

  const pageButton = ind => (
    <button
      key={ind}
      className={paginationButton(totalPages === 1, ind === pageNumber, theme)}
      {...(totalPages !== 1 &&
        pageNumber !== ind && {
          onClick: () => handlePageChange(ind),
        })}
    >
      {ind}
    </button>
  );

  const ellipsesElement = <span className="pagination-dots">...</span>;

  const fromIndex = (pageNumber - 1) * pageSize + 1;
  const toIndex = pageNumber * pageSize;

  return (
    !!totalResults && (
      <Row justify={justify ?? (withPageDropdown ? 'space-between' : 'left')}>
        {withPageDropdown && (
          <Dropdown
            noClear
            disabled={totalResults < 1}
            options={pageSizes ?? defaultPageSizes}
            value={pageSize}
            onChange={handlePageSizeChange}
            className={pageSizeDropdown}
            onTop={dropDownListOptionsOnTop}
          />
        )}

        <div className={paginationContainer}>
          {arrowsButton(pageNumber === 1 || noResults, 'left')}
          {needEllipses ? (
            <>
              {pageButton(1)}
              {pageNumber > 3 && ellipsesElement}
              {pageNumber > 2 && pageButton(pageNumber - 1)}
              {pageNumber !== 1 && pageNumber !== totalPages && pageButton(pageNumber)}
              {pageNumber < totalPages - 1 && pageButton(pageNumber + 1)}
              {totalPages - pageNumber > 2 && ellipsesElement}
              {pageButton(totalPages)}
            </>
          ) : (
            Array(totalPages)
              .fill()
              .map((_, ind) => pageButton(ind + 1))
          )}
          {arrowsButton(pageNumber === totalPages || noResults, 'right')}
          <p className={shownResultsIndicator(theme)}>
            {showCurrentIndex && <span>{`${fromIndex} - ${toIndex > totalResults ? totalResults : toIndex} `}</span>}(
            {totalResults})
          </p>
        </div>
      </Row>
    )
  );
});

Pagination.propTypes = {
  onChange: PropTypes.func,
  pageSizes: PropTypes.array,
  withPageDropdown: PropTypes.bool,
  pageNumber: PropTypes.number,
  totalResults: PropTypes.number,
  pageSize: PropTypes.number,
  noInitialCall: PropTypes.bool,
  justify: PropTypes.string,
  showArrows: PropTypes.bool,
  showCurrentIndex: PropTypes.bool,
  filterKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  sortingKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  requiredFilterKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  defaultQueryParams: PropTypes.object,
  dropDownListOptionsOnTop: PropTypes.bool,
};
