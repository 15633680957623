import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import {
  hasCryptoAmountForCart,
  useCurrencies,
  DEFAULT_FIAT_CURRENCY,
  PAYMENT_METHODS_CURRENCIES,
  PAYMENT_METHOD,
} from '@oneecosystem/dealshaker-core';
import { Button } from 'components/UIExternal';
import { PriceLabel } from 'components/shared';
import { useUser } from 'stores';
import { promoCodeType } from 'enums';
import { getPrecisionByExternalPaymentMethod } from 'utils';
import { summaryBox, spaceRowSection, rightText, totalSection, goToCheckoutButton, disclaimerText } from './styles';

const SummaryPricesCard = ({ shoppingCart, linkTo }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { currentAccount } = useUser();
  const selectedCurrency = useCurrencies(currenciesState => currenciesState?.selectedCurrency);
  const hasPromoCodes = shoppingCart?.businesses?.some(el => el.promoCode);
  const { coinWalletBalance, oespWalletBalance } = currentAccount;
  const insufficientFundsError = hasCryptoAmountForCart({ shoppingCart, coinWalletBalance, oespWalletBalance });
  const { cashPaymentMethodId, cryptoPaymentMethodId } = shoppingCart ?? {};
  const cryptoDesiredCurrency = PAYMENT_METHODS_CURRENCIES[cryptoPaymentMethodId]?.code;
  const businessesFiatCurrency = selectedCurrency?.code ?? DEFAULT_FIAT_CURRENCY.code;
  const userFiatCurrency = PAYMENT_METHODS_CURRENCIES[cashPaymentMethodId]
    ? PAYMENT_METHODS_CURRENCIES[cashPaymentMethodId]
    : businessesFiatCurrency;
  const isCardPaymentInDifferentCurrency =
    userFiatCurrency !== DEFAULT_FIAT_CURRENCY.code && cashPaymentMethodId === PAYMENT_METHOD.STRIPE;

  const isFiatDiscount = promoCode => promoCode?.typeId === promoCodeType.fiat;
  const promoCodePrice = promoCode =>
    isFiatDiscount(promoCode) ? (
      <PriceLabel
        data-id="promo-code-discount"
        fiat={promoCode?.value}
        fiatOriginCurrency={promoCode?.currencyCode}
        fiatDesiredCurrency={userFiatCurrency}
        fiatPrecision={getPrecisionByExternalPaymentMethod(userFiatCurrency)}
        total={promoCode?.discount}
      />
    ) : (
      <span data-id="promo-code-discount">{promoCode?.value}%</span>
    );

  return (
    <div className={summaryBox}>
      <div className={spaceRowSection(theme.gray300)} data-id="shopping-cart-subtotal-contrainer">
        <p className="subtotal">{getText('subtotal')}</p>
        <p className={rightText} data-id="shopping-cart-subtotal">
          <PriceLabel
            fiat={shoppingCart.subTotalLocalPriceFiat}
            fiatOriginCurrency={businessesFiatCurrency}
            fiatDesiredCurrency={userFiatCurrency}
            crypto={shoppingCart.subTotalPriceCrypto}
            fiatPrecision={getPrecisionByExternalPaymentMethod(userFiatCurrency)}
            total={shoppingCart.subTotalLocalPrice}
          />
        </p>
      </div>
      {hasPromoCodes &&
        shoppingCart?.businesses
          ?.filter(business => business?.promoCode)
          .map(({ promoCode }) => (
            <div
              className={spaceRowSection(theme.green2)}
              key={promoCode?.code}
              data-id="promo-code-discount-container"
            >
              <p className="subtotal">
                <span data-id="promo-code-text">{promoCode?.code}</span>&nbsp;&nbsp; - {promoCodePrice(promoCode)}
              </p>
              <p className={rightText}>
                (
                <PriceLabel
                  fiat={promoCode?.discountFiat}
                  fiatOriginCurrency={promoCode?.currencyCode}
                  fiatDesiredCurrency={userFiatCurrency}
                  fiatCrypto={promoCode?.discountCrypto}
                  fiatPrecision={getPrecisionByExternalPaymentMethod(userFiatCurrency)}
                  total={promoCode?.discount}
                />
                )
              </p>
            </div>
          ))}
      {shoppingCart?.paymentProviderFee && (
        <div className={spaceRowSection(theme.gray300)} data-id="shopping-cart-payment-fee-contrainer">
          <p className="subtotal">{getText('paymentFee')}</p>
          <p className={rightText} data-id="shopping-cart-payment-fee">
            <PriceLabel
              fiat={shoppingCart?.paymentProviderFee?.totalPriceFiat}
              fiatDesiredCurrency={userFiatCurrency}
              fiatPrecision={getPrecisionByExternalPaymentMethod(userFiatCurrency)}
              total={shoppingCart?.paymentProviderFee?.totalPrice}
            />
          </p>
        </div>
      )}
      <div className={totalSection(theme)} data-id="shopping-cart-total-container">
        <label>{getText('total')}:</label>
        <p className={rightText} data-id="shopping-cart-total">
          <PriceLabel
            fiat={shoppingCart.totalLocalPriceFiat}
            fiatOriginCurrency={businessesFiatCurrency}
            fiatDesiredCurrency={userFiatCurrency}
            fiatPrecision={getPrecisionByExternalPaymentMethod(userFiatCurrency)}
            crypto={shoppingCart.totalPriceCrypto}
            cryptoDesiredCurrency={cryptoDesiredCurrency}
            fiatOnly={false}
          />
        </p>
      </div>
      {isCardPaymentInDifferentCurrency && (
        <div className={disclaimerText(theme)}>{getText('eurPaymentDisclaimer')}</div>
      )}
      {linkTo && (
        <Button className={goToCheckoutButton(theme)} linkTo={linkTo} disabled={!!insufficientFundsError}>
          {getText('goToCheckout')}
          {insufficientFundsError && <span className="insufficientFunds">({getText(insufficientFundsError)})</span>}
        </Button>
      )}
    </div>
  );
};

SummaryPricesCard.propTypes = {
  shoppingCart: PropTypes.object,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default SummaryPricesCard;
