import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { isMobile } from 'react-device-detect';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { chartTitle, chartContainer, mr30 } from '../styles';

const BarChartComponent = ({ data }) => {
  const theme = useTheme();
  const { getText } = useTranslations();

  return (
    <div className={`${chartContainer} ${mr30}`}>
      <h6 className={chartTitle(theme)}>{getText('topSellingDeals')}</h6>
      <ResponsiveContainer width="100%" aspect={isMobile ? 1.0 / 1.0 : 2.0 / 1.0}>
        <BarChart width={500} height={500} data={data} layout="vertical" margin={{ right: 30 }}>
          <XAxis type="number" hide />
          <YAxis type="category" width={100} dataKey="key" axisLine={false} tickLine={false} />
          <Bar
            dataKey="value"
            fill={theme.blue}
            label={{ value: 'value', angle: 0, position: 'right', fill: theme.gray300 }}
            radius={[2, 2, 2, 2]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

BarChartComponent.propTypes = {
  data: PropTypes.array,
};

export default BarChartComponent;
