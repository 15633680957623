import moment from 'moment';

export const removeTimeZone = date => date.format('YYYY-MM-DDTHH:mm:ss');

export const convertUTCDateToLocalDate = date => {
  let temp = date;
  temp = temp?.replace(/Z$/, '');
  if (temp) {
    const tempDate = new Date(temp);
    const newDate = new Date(tempDate.getTime() + tempDate.getTimezoneOffset() * 60 * 1000);
    const offset = tempDate.getTimezoneOffset() / 60;
    const hours = tempDate.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }
};
export const getTimeFromNow = inputDate => moment(convertUTCDateToLocalDate(inputDate)).fromNow();

export const toUtc = date => moment.utc(date);
