import { css } from 'emotion';

export const cardElementTitle = css({
    textAlign: 'center',

    '@media (max-width: 1024px)': {
      marginTop: '2em', // Medium screens (tablets)
    },
  });

export const subTitleHolder = css({
    display: 'flex',
    justifyContent: 'flex-start',
  });

export const cardElementSubTitle = css({
    paddingTop: '0.4em',
  });

export const processing = css({
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '1.175rem',
  });

export const clearfix = css({
    clear: 'both',
  });

export const red = css({
    color: 'red',
  });
export const cardElementContainer = css({
    border: '0.07em solid #d9d9d9',
    padding: '2em',
    marginTop: '2em',
    marginBottom: '4em'
  });

export const payNow = css({
    width: '100%',
    marginTop: '2em'
  });

export const paymentInfoContainer = css({
    width: '100%',
    marginTop: '0.5em',
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'space-between',
  });