export default {
  // private routes
  myProfile: '/my-profile',
  couponDetails: '/coupon-details',
  requestRefund: '/request-refund/:couponId',
  merchantOffice: '/merchant-office',
  bundledTransactions: '/my-profile/settlement/:transactionId/bundled-transactions',
  merchantAccounts: '/merchant-accounts',
  addBusinessAccount: '/add-merchant-account',
  editBusinessAccount: '/edit-merchant-account/:businessId',
  addBusinessAddress: '/add-business-address',
  editBusinessAddress: '/edit-business-address/:addressId',
  notifications: '/notifications',
  addDeliveryAddress: '/add-delivery-address',
  editDeliveryAddress: '/edit-delivery-address/:addressId',
  addDealPage: '/add-deal',
  editDeal: '/edit-deal/:dealId',
  leaveReviewPage: '/deal/:dealId/leave-review',
  editReviewPage: '/edit-review/:reviewId',
  disputeReviewPage: '/dispute-review/:reviewId',
  buyCredits: '/merchant-office/buy-credits',
  startChat: '/start-chat',
  ongoingChat: '/chat',
  coupons: '/coupons',
  wishList: '/wishlist',
  clearing: '/clearing',
  profileInformation: '/profile-information',
  myDeals: '/my-deals',
  messages: '/messages',
  bankDetails: '/bank-details',
  brandMaterial: '/brand-materials/:id',
  shoppingCart: '/shopping-cart',
  shoppingCartCheckout: '/shopping-cart/checkout',
  shoppingCartCheckoutDelivery: '/shopping-cart/checkout/delivery',
  shoppingCartCheckoutPaymentMethods: '/shopping-cart/checkout/payment-methods',
  shoppingCartCheckoutSummary: '/shopping-cart/checkout/summary',
  nearbyMerchantMap: '/nearbyMerchantMap',
  orders: '/orders',
  guestOrdersDetails: '/orders-details',
  newPromoCode: '/create-promo-code',
  addressBook: '/address-book',
  payOrderWithCard: '/order/:orderId/pay-with-card',
  cardPaymentConfirmation: '/order/:orderId/card-payment-confirmation',

  // public routes
  redirectCallback: '/authorization-callback',
  logout: '/logout',
  dealDetailsPage: '/deal/:dealName',
  dealConnectedDealsPage: '/deal/:dealName/connected-deals',
  dealPreviewPage: '/deal/preview/:dealName',
  dealReviewsPage: '/deal/:dealName/reviews',
  dealListingPage: '/listing',
  redeemCouponPage: '/redeem-coupon',
  publicMerchant: '/merchant/:businessId',
  nearbyMerchants: '/nearby-merchants',
  notFound: '/not-found',
  insufficientRights: '/insufficient-rights',
  privacyPolicy: '/privacy-policy',
  termsAndConditional: '/terms-and-conditions',
  brandGuide: '/brand-guide',
  ourGlobalStructure: '/our-global-structure',
  merchantList: '/merchant-list',
  clearingSettlementExplained: '/clearing-settlement-explained',
  homePage: '/',
};
