import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { stringify } from 'query-string';
import { Menu, Popover } from 'antd';
import { Button, Icon } from 'components';
import { useUser } from 'stores';
import { Routes } from 'enums';
import { categoryIcons } from 'mappings/category';
import {
  hamburgerBtn,
  categoriesFilterContent,
  popoverWrapper,
  arrow,
  blackTextLink,
  LightBlueHeaderContainer,
  nearbyButton,
} from './styles';
import Localization from '../../../layouts/LocalizationPanel';

const MobileMegaMenu = ({ categories }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const { isMerchant } = useUser();
  const [isExpanded, setIsExpanded] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const query = item => stringify({ categoryId: item?.id });

  const toggleCollapse = () => setIsExpanded(!isExpanded);
  const toggleVisible = () => setVisible(!visible);

  const addSubMenuLevel = category => (
    <Menu.SubMenu
      key={category.id}
      title={
        <div className="flex space-between">
          <Button
            type="link"
            small
            className={blackTextLink(theme)}
            onClick={() => {
              toggleVisible();
              setOpenKeys([]);
              history.push(`/listing?${query(category)}`);
            }}
          >
            {getText(category.name)}
          </Button>
          <Button
            type="link"
            small
            className={arrow(theme)}
            onClick={() => {
              toggleCollapse();
              setOpenKeys(
                openKeys.indexOf(category.id.toString()) > -1
                  ? openKeys.filter(k => k !== category.id.toString())
                  : [openKeys[0], ...[category.id.toString()]],
              );
            }}
          >
            <span className={`${openKeys.includes(category.id.toString()) && 'isExpanded'} icon-SingleChevron`} />
          </Button>
        </div>
      }
    >
      {category.subCategories &&
        category.subCategories.length > 0 &&
        category.subCategories.map(subCategory => (
          <Menu.Item key={subCategory.id}>
            <Button
              type="link"
              small
              className={blackTextLink(theme)}
              onClick={() => {
                toggleVisible();
                setOpenKeys([]);
                history.push(`/listing?${query(subCategory)}`);
              }}
            >
              {getText(subCategory.name)}
            </Button>
          </Menu.Item>
        ))}
    </Menu.SubMenu>
  );

  const categoryFilter = (
    <>
      <Button type="link" small linkTo={Routes.nearbyMerchants}>
        <Icon iconName="las la-map-marker" size={20} className={nearbyButton(theme)} />
        <p className={nearbyButton(theme)}>{getText('nearByMerchants')}</p>
      </Button>
      {isMerchant && (
        <Button type="info" small linkTo={Routes.addDealPage}>
          {getText('addDeal')}
        </Button>
      )}
      <div className={LightBlueHeaderContainer(theme)}>
        <Localization isMobileView />
      </div>
      <Menu
        className={categoriesFilterContent}
        selectedKeys={[]}
        openKeys={openKeys}
        mode="inline"
        triggerSubMenuAction="click"
        expandIcon={<></>}
      >
        {categories &&
          categories.map(category =>
            category.subCategories && category.subCategories.length > 0 ? (
              <Menu.SubMenu
                key={category.id}
                title={
                  <div className="flex space-between">
                    <Button
                      type="link"
                      small
                      className={blackTextLink(theme)}
                      onClick={() => {
                        toggleVisible();
                        setOpenKeys([]);
                        history.push(`/listing?${query(category)}`);
                      }}
                    >
                      {getText(category.name)}
                    </Button>
                    <Button
                      type="link"
                      small
                      className={arrow(theme)}
                      onClick={() => {
                        toggleCollapse();
                        setOpenKeys(category.id.toString() === openKeys[0] ? [] : [category.id.toString()]);
                      }}
                    >
                      <span
                        className={`${openKeys[0] === category.id.toString() && 'isExpanded'} icon-SingleChevron`}
                      />
                    </Button>
                  </div>
                }
              >
                {category.subCategories?.length > 0 &&
                  category.subCategories.map(subCategory =>
                    subCategory.subCategories && subCategory.subCategories.length > 0 ? (
                      addSubMenuLevel(subCategory)
                    ) : (
                      <Menu.Item key={subCategory.id}>
                        <Button
                          type="link"
                          small
                          className={blackTextLink(theme)}
                          style={{ padding: 0 }}
                          onClick={() => {
                            toggleVisible();
                            setOpenKeys([]);
                            history.push(`/listing?${query(subCategory)}`);
                          }}
                        >
                          {getText(subCategory.name)}
                        </Button>
                      </Menu.Item>
                    ),
                  )}
              </Menu.SubMenu>
            ) : (
              <Menu.Item
                onClick={() => {
                  history.push(`/listing?${query(category)}`);
                }}
                key={category.id || category.name}
              >
                {category.id === 0 && <span className={categoryIcons[category.name]} />}
                {getText(category.name)}
              </Menu.Item>
            ),
          )}
      </Menu>
    </>
  );
  return (
    <>
      <Popover
        content={categoryFilter}
        arrowPointAtCenter
        placement="bottomRight"
        overlayClassName={popoverWrapper}
        trigger="click"
        onOpenChange={() => {
          setOpenKeys([]);
          toggleVisible();
        }}
        open={visible}
      >
        <Button type="link" small onClick={toggleVisible}>
          {visible ? (
            <Icon iconName="las la-times" size={30} className={hamburgerBtn(theme)} />
          ) : (
            <Icon iconName="las la-bars" size={30} className={hamburgerBtn(theme)} />
          )}
        </Button>
      </Popover>
    </>
  );
};

MobileMegaMenu.propTypes = {
  categories: PropTypes.array,
};

export default MobileMegaMenu;
