import { EXTERNAL_PAYMENT_PROVIDERS } from '@oneecosystem/dealshaker-core';

export const getPrecisionByExternalPaymentMethod = methodCode => {
  if (!methodCode) return null;

  const method = Object.values(EXTERNAL_PAYMENT_PROVIDERS).find(
    PROVIDER => PROVIDER.CODE.toLowerCase() === methodCode.toLowerCase(),
  );

  return method?.precision;
};
