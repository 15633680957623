import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const LightBlueHeaderContainer = theme =>
  css({
    backgroundColor: theme.background200,
    color: theme.gray550,
    marginTop: '.6em',
  });

export const hamburgerBtn = theme =>
  css({
    color: theme.white,
  });

export const categoriesFilterContent = css({
  borderRight: 'none !important',
  maxHeight: 400,
  overflow: 'auto',
  overflowX: 'hidden !important',
  '& .icon-Star': { marginRight: 10 },
  '& .icon-Percent': { marginRight: 10 },
});

export const arrow = theme =>
  css({
    color: theme.gray100,
    '& .isExpanded': { transform: 'rotate(90deg)' },
    '& .icon-SingleChevron': { fontSize: '1.5625rem' },
  });

export const popoverWrapper = css({
  [Mobile]: {
    width: '100% !important',
    left: '0px !important',
    borderRadius: 4,
    '& .ant-popover-inner-content': { padding: 0 },
    '& .ant-popover-content': { width: '100%' },
    '& .ant-popover-arrow': { display: 'none' },
  },
  position: 'fixed !important',
  paddingTop: '0px !important',
});

export const blackTextLink = theme =>
  css({
    color: theme.gray100,
  });

export const nearbyButton = theme =>
  css({
    color: theme.gray100,
    marginLeft: '.3em',
  });
